import React, { useEffect } from "react"
import { DateHelper } from "@bonsaichecklist/bonsai-utils"
import {
  Avatar,
  ChecklistVisibilityIcon,
  Link,
  RunStatusCard,
  ToggleSwitch,
  Alert,
  Tooltip,
} from "~src/components"
import { Icon, IconName } from "~src/components/Icon"
import { ChecklistHelper } from "~src/common/lib"
import { routes } from "~src/routes"
import { ChecklistService } from "~src/services"
import dayjs from "dayjs"
import { useState, useActions } from "~src/store"
import cross from "../../../public/icons/cross.svg"
import { CategoryDropdown } from "../CategoryDropdown"

export interface ChecklistMetadataProps {
  checklist?: Checklist
  checklistNextRun?: Date
  starredChecklist?: StarredChecklistRoot
  subbedChecklist?: Checklist
  config?: {
    orientation?: "vertical" | "horizontal"
    showVisibility?: boolean
    showOwner?: boolean
  }
  publicRun?: GenericObject
  featuredChecklists?: number
}

const defaultConfig: ChecklistMetadataProps["config"] = {
  orientation: "horizontal",
  showVisibility: false,
  showOwner: false,
}

export function ChecklistMetadata({
  checklist,
  starredChecklist,
  subbedChecklist,
  config,
  publicRun,
}: ChecklistMetadataProps): JSX.Element {
  const {
    checklist: { checklistVerified, visibilityChanged },
    auth: { user },
    system: { featuredChecklists },
  } = useState()

  const {
    checklist: { setVerified, changeVisibility, resetChangeVisibility },
    system: { showToastAlert, changeFeaturedChecklistsCounter },
  } = useActions()

  config = { ...defaultConfig, ...config }

  const [metaData, setMetaData] = React.useState(null)
  const [isFeatured, setIsFeatured] = React.useState(checklist?.isFeatured)
  const [featureThresholdMsg, setFeatureThresholdMsg] = React.useState<boolean>(
    false
  )

  React.useEffect(() => {
    if (featuredChecklists < 3) setFeatureThresholdMsg(false)
  }, [featuredChecklists])

  const starredChklst =
    starredChecklist && (starredChecklist.starredChecklist || subbedChecklist)

  const RemovedFromCommunity = visibilityChanged
    ? Boolean(visibilityChanged?.removedFromCommunityAt)
    : Boolean(checklist?.removedFromCommunityAt)

  React.useEffect(() => {
    if (config.orientation !== "vertical") return

    async function getMetaData() {
      const data = await ChecklistService.getMetaData(
        checklist
          ? checklist.slug
          : starredChklst?.slug || subbedChecklist?.slug
      )

      if (!data) return

      setMetaData(data)
    }

    getMetaData()
  }, [
    checklist,
    config.orientation,
    starredChklst?.slug,
    subbedChecklist?.slug,
  ])

  useEffect(() => {
    resetChangeVisibility()
  }, [])

  let helper = new ChecklistHelper(
    checklist || (starredChecklist && starredChklst)
  )

  if (subbedChecklist) helper = new ChecklistHelper(subbedChecklist)

  const { isOwner } = helper

  const isOwned = helper.isOwner(user)

  const showTimestamp = checklist
    ? checklist.createdAt || checklist.updatedAt
    : starredChecklist
    ? starredChklst?.updatedAt
    : subbedChecklist && helper.findSubscribedAt(user)

  let subCount = checklist?.followers?.length

  if (starredChklst) {
    subCount = starredChklst.followers?.length
  }

  if (subbedChecklist) {
    subCount = subbedChecklist.followers?.length
  }

  const items: React.ReactNode[] = [
    config?.showVisibility && (
      <ChecklistVisibilityIcon
        key="visibility"
        visibility={checklist.visibility}
      />
    ),
    <SubscriberCount count={subCount} iconName="Bell" key="stars" />,
    showTimestamp && (
      <Timestamp
        checklist={checklist || starredChecklist || subbedChecklist}
        isSubscription={Boolean(subbedChecklist)}
        key="timestamp"
      />
    ),
    <FeaturedChecklist checklist={checklist} key={"featuredChecklist"} />,
  ].filter((v) => Boolean(v))

  async function handleVerifiedChange() {
    setVerified(!checklistVerified)
    if (checklistVerified) setIsFeatured(false)

    try {
      await ChecklistService.markChecklistVerified(
        checklist?.slug,
        !checklistVerified
      )
    } catch (error) {
      showToastAlert({
        text: "Error",
        autoHide: true,
        type: "warning",
        iconVariant: "solid",
      })
      setVerified(!checklistVerified)
    }
  }

  async function handleFeaturedChecklist() {
    if (!checklistVerified) return

    const featuredChklStatus = !isFeatured
    if (featuredChklStatus && featuredChecklists >= 3) {
      setFeatureThresholdMsg(true)
      return
    }

    setIsFeatured(featuredChklStatus)
    setFeatureThresholdMsg(false)
    changeFeaturedChecklistsCounter(featuredChklStatus)

    try {
      const result = await ChecklistService.toggleFeatureChecklist(
        checklist?.slug,
        featuredChklStatus
      )

      if (!result) {
        setIsFeatured(false)
        setFeatureThresholdMsg(true)
      }
    } catch (error) {
      setIsFeatured(!featuredChklStatus)
      showToastAlert({
        text: error.message,
        autoHide: true,
        type: "warning",
        iconVariant: "solid",
      })
    }
  }

  function handleChangeVisibility() {
    const text =
      "Heads up, \n\nThis will remove the template from Community. The user will not be notified of this. Don’t forget to send them an email explaining the reason why the template has been removed."
    if (!RemovedFromCommunity) {
      if (window.confirm(text) === true) {
        changeVisibility(checklist.slug)
        setVerified(false)
        setIsFeatured(false)
        return
      }
    }
    if (RemovedFromCommunity) {
      changeVisibility(checklist.slug)
    }
  }

  const explorePageLink = (
    <Link className="text-xs no-underline" href={routes.generic.community()}>
      Explore page
    </Link>
  )

  const attemptToAssignCategory = async (category?: TemplateCategory) => {
    try {
      await ChecklistService.assignCategoryToTemplate(
        checklist
          ? checklist.slug
          : starredChklst?.slug || subbedChecklist?.slug,
        category
      )

      showToastAlert({
        text: "Category Changed",
        autoHide: true,
        type: "success",
        iconVariant: "solid",
      })
    } catch (error) {
      showToastAlert({
        text: error.message,
        autoHide: true,
        type: "warning",
        iconVariant: "solid",
      })
    }
  }

  return (
    <aside className="text-xs text-gray-600">
      {config.orientation === "horizontal" && (
        <div className="flex">
          {config?.showOwner && (
            <div className="flex mr-3 sm:mr-8">
              <OwnerAvatar
                checklist={checklist || subbedChecklist || starredChklst}
                key="owner"
              />
            </div>
          )}
          <div className="flex items-center">
            {items.map((Component, index) => [
              <div
                className={`${index !== items.length - 1 && "mr-3 sm:mr-8"}`}
                key={`component-${index}`}
              >
                {Component}
              </div>,
            ])}
          </div>
        </div>
      )}
      {config.orientation === "vertical" && (
        <div>
          <table style={{ minWidth: "369px" }}>
            <tbody>
              {!user && publicRun?.startedAt && (
                <tr>
                  <td
                    className={publicRun?.startedAt ? "pb-8" : ""}
                    colSpan={2}
                  >
                    <RunStatusCard run={publicRun} />
                  </td>
                </tr>
              )}

              {user && metaData?.recentRun?.startedAt && (
                <tr>
                  <td className={metaData?.recentRun ? "pb-8" : ""} colSpan={2}>
                    <RunStatusCard run={metaData?.recentRun} />
                  </td>
                </tr>
              )}
              {config?.showOwner && (
                <tr>
                  <td className="p-2 pt-0">Template creator</td>
                  <td className="p-2 pt-0">
                    <ChecklistCreator checklist={checklist} key="owner" />
                  </td>
                </tr>
              )}
              <tr>
                <td className="p-2">Creation date</td>
                <td className="p-2">
                  {DateHelper.localeDate(checklist.createdAt)}
                </td>
              </tr>
              <tr>
                <td className="p-2">Last updated</td>
                <td className="p-2">
                  <Timestamp
                    checklist={checklist || starredChecklist || subbedChecklist}
                    icon={false}
                    key="timestamp"
                  />
                </td>
              </tr>
              {metaData?.nextRun && isOwner && (
                <tr>
                  <td className="p-2">Next Schedule Run Date</td>
                  <td className="p-2">
                    <Link
                      className="text-xs"
                      href={routes.checklists.schedule(
                        checklist?.slug,
                        checklist?.owner?.username
                      )}
                    >
                      {dayjs(metaData?.nextRun).format("MMMM D YYYY, h:mmA")}
                    </Link>
                  </td>
                </tr>
              )}
              <tr>
                <td className="px-2 py-4">Status</td>
                <td className="px-2 py-4">
                  <ChecklistVisibilityIcon
                    iconColor="blue"
                    key="visibility"
                    visibility={
                      visibilityChanged?.visibility || checklist.visibility
                    }
                  />
                </td>
              </tr>
              {(isOwned || user?.role === "admin") && (
                <tr>
                  <td className="p-2">Category</td>
                  <td className="p-2">
                    <CategoryDropdown
                      assignedCategory={checklist?.category}
                      isOwner={isOwned}
                      onCategoryClicked={attemptToAssignCategory}
                    />
                  </td>
                </tr>
              )}
              <tr>
                <td className="p-2">Copies</td>
                <td className="p-2">{metaData?.copies || 0}</td>
              </tr>
              <tr>
                <td className="p-2">Subscribers</td>
                <td className="p-2">{subCount}</td>
              </tr>
              <tr>
                <td className="p-2">Embeds</td>
                <td className="p-2">{metaData?.embeds || 0}</td>
              </tr>

              {user?.role === "admin" && (
                <>
                  <tr>
                    <td className="px-2 pt-8 font-normal text-gray-500 text-2xs">
                      ADMIN OPTIONS
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2">
                      <label
                        className="form-check-label"
                        htmlFor="markVerifiedCheckbox"
                      >
                        Mark as Verified
                      </label>
                    </td>
                    <td className="p-2 form-check form-switch">
                      <ToggleSwitch
                        checked={checklistVerified}
                        onClick={handleVerifiedChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2">
                      <label
                        className="form-check-label"
                        htmlFor="markVerifiedCheckbox"
                      >
                        Feature template
                      </label>
                    </td>
                    <td className="p-2 form-check form-switch">
                      <ToggleSwitch
                        checked={isFeatured}
                        isChecklistVerified={checklistVerified}
                        onClick={handleFeaturedChecklist}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="p-2">Shared to Community</td>
                    {!RemovedFromCommunity && (
                      <td
                        className="p-2 text-blue-500 cursor-pointer"
                        onClick={handleChangeVisibility}
                      >
                        Remove
                      </td>
                    )}
                    {RemovedFromCommunity && (
                      <div className="p-2">
                        <td className="px-1">
                          <img src={cross} />
                        </td>
                        <td>
                          {`Removed on ${dayjs(
                            checklist?.removedFromCommunityAt ||
                              visibilityChanged?.removedFromCommunityAt
                          ).format("MMMM Do YYYY")}`}
                        </td>
                      </div>
                    )}
                  </tr>
                  {RemovedFromCommunity && (
                    <tr>
                      <td className="px-2"></td>
                      <td
                        className="px-2 text-blue-500 cursor-pointer"
                        onClick={handleChangeVisibility}
                      >
                        Re-add to Community
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="p-2">Creator email</td>
                    <td className="p-2">
                      <Link
                        className="text-xs"
                        href={`mailto:${checklist?.owner?.email}`}
                      >
                        {checklist?.owner?.email}
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      {featureThresholdMsg && (
                        <Alert
                          autoHide
                          className="mt-2"
                          icon="TriangleAlert"
                          onHide={() => setFeatureThresholdMsg(false)}
                          type="warning"
                        >
                          <div
                            className={`flex items-center pl-3`}
                            role="alert"
                          >
                            <p className="text-xs">
                              There are already 3 featured templates on the
                              &nbsp;{explorePageLink}. Unfeature one of them to
                              feature this template.
                            </p>
                          </div>
                        </Alert>
                      )}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      )}
    </aside>
  )
}

function OwnerAvatar({
  checklist,
  variant = "muted",
}: {
  checklist: Checklist
  variant?: "normal" | "muted"
}): JSX.Element {
  return (
    <Link
      className="flex items-center text-xs"
      href={routes.users.profile(checklist?.owner?.username)}
      variant={variant}
    >
      <Avatar
        avatarUrl={checklist?.owner?.avatar?.smallMedium?.url}
        className="mr-2"
        size={24}
      />
      {checklist?.owner?.username}
    </Link>
  )
}

function ChecklistCreator({
  checklist,
}: {
  checklist: Checklist
}): JSX.Element {
  return (
    <Link
      className="flex items-center text-xs"
      href={routes.users.profile(checklist?.owner?.username)}
      variant="normal"
    >
      <Avatar
        avatarUrl={checklist?.owner?.avatar?.smallMedium?.url}
        className="mr-2"
        size={24}
      />
      {checklist?.owner?.username}
    </Link>
  )
}

function SubscriberCount({
  count,
  iconName,
}: {
  count: number
  iconName?: IconName
}): JSX.Element {
  return (
    <Tooltip
      tooltipTitle={`${count > 0 ? count : "No"} subscribers to this template`}
    >
      <span aria-label="Subscriber Count">
        <Icon className="mr-1 sm:mr-2" name={iconName || "Bell"} /> {count}
      </span>
    </Tooltip>
  )
}

function FeaturedChecklist({
  checklist,
}: {
  checklist: Checklist
}): JSX.Element {
  if (!checklist?.isFeatured) return null
  return (
    <span aria-label="Subscriber Count" title={`Featured template`}>
      <Icon className="mr-1 sm:mr-2" name="Pin" /> featured
    </span>
  )
}

function Timestamp({
  checklist,
  icon = true,
  isSubscription = false,
}: {
  checklist?: Checklist | StarredChecklistRoot
  icon?: boolean
  isSubscription?: boolean
}): JSX.Element {
  const {
    auth: { user },
  } = useState()

  let timestamp: Date | string =
    (checklist as StarredChecklistRoot).starredChecklist?.updatedAt ||
    (checklist as Checklist).updatedAt ||
    (checklist as Checklist).createdAt

  if (isSubscription) {
    timestamp = new ChecklistHelper(checklist as Checklist)?.findSubscribedAt(
      user
    )
  }

  return (
    <Tooltip
      tooltipTitle={`${
        checklist &&
        ((checklist as Checklist).updatedAt ? "Updated" : "Created")
      } at ${DateHelper.localeDate(timestamp)}`}
    >
      <p className="inline cursor-info">
        {icon && <Icon className="mr-1 sm:mr-2" name="Clock" />}
        {DateHelper.fromNow(timestamp)}
      </p>
    </Tooltip>
  )
}
