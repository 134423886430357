import { DraftHandleValue, EditorState } from "draft-js"
import React from "react"
import { useMethods } from "react-use"
import { CHECKLIST_DESCRIPTION_MAX_LENGTH } from "~src/common/constants"
import {
  Button,
  ChecklistDescriptionDisplay,
  Editor,
  EditorCommand,
  Icon,
  IconButton,
} from "~src/components"
import { EditorHelper } from "~src/common/lib"
import { RunService } from "~src/services"
import { MarkdownHelper } from "@bonsaichecklist/bonsai-utils"

enum ChangeStatus {
  Default = "DEFAULT",
  Editing = "EDITING",
  Saving = "SAVING",
}

interface State {
  run?: ChecklistRun
  status?: ChangeStatus
}

function createMethods(state: State) {
  return {
    edit() {
      return { ...state, status: ChangeStatus.Editing }
    },
    cancel() {
      return { ...state, status: ChangeStatus.Default }
    },
    saving() {
      return { ...state, status: ChangeStatus.Saving }
    },
    save(run: ChecklistRun) {
      return {
        ...state,
        status: ChangeStatus.Default,
        run,
      }
    },
  }
}

export function ChecklistRunDescription({
  checklist,
  isOwner,
  run: initialRun,
  showEditIcon = true,
}: {
  checklist: Checklist
  isOwner?: boolean
  run: ChecklistRun
  showEditIcon?: boolean
}): JSX.Element {
  const initialState: State = {
    run: initialRun,
    status: ChangeStatus.Default,
  }

  const [state, methods] = useMethods(createMethods, initialState)

  React.useEffect(() => {
    methods.save(initialRun)
  }, [initialRun.slug, checklist])

  return (
    <>
      {state.status === ChangeStatus.Default && (
        <DisplayDescription
          isOwner={isOwner}
          onEdit={methods.edit}
          run={state.run}
          showEditIcon={showEditIcon}
        />
      )}
      {state.status === ChangeStatus.Editing && (
        <DescriptionEdit
          checklist={checklist}
          onCancel={methods.cancel}
          onSave={methods.save}
          onSaving={methods.saving}
          run={state.run}
        />
      )}
      {state.status === ChangeStatus.Saving && (
        <DescriptionSaving run={initialState.run} />
      )}
    </>
  )
}

function DisplayDescription({
  run,
  isOwner,
  onEdit,
  showEditIcon,
}: {
  run: ChecklistRun
  isOwner: boolean
  onEdit: () => void
  showEditIcon: boolean
}): JSX.Element {
  const { description } = run
  return (
    <div className="flex items-center">
      <ChecklistDescriptionDisplay description={description} />
      {showEditIcon && isOwner && (
        <div>
          {description?.length ? (
            <IconButton
              aria-label="edit"
              icon="Pencil"
              onClick={onEdit}
              size="sm"
              variant="link"
            />
          ) : (
            <Button onClick={onEdit} size="sm" variant="link">
              <Icon className="mr-2" name="Pencil" size="sm" />
              Add description
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

function DescriptionEdit({
  checklist,
  run,
  onCancel,
  onSave,
  onSaving,
}: {
  checklist: Checklist
  run: ChecklistRun
  onCancel: () => void
  onSave: (run: ChecklistRun) => void
  onSaving: () => void
}): JSX.Element {
  const [description, setDescription] = React.useState(
    (run.description as ChecklistDescription)?.markdown
  )

  const [descriptionValidation, setDescriptionValidation] = React.useState<
    boolean
  >(false)

  async function handleSave(): Promise<void> {
    if (description.length > CHECKLIST_DESCRIPTION_MAX_LENGTH) {
      setDescriptionValidation(true)
      return
    }

    onSaving()
    const checklistRun = await RunService.update(checklist.slug, run?.slug, {
      description,
    })
    onSave(checklistRun)
  }

  function handleSetContent(html: string): void {
    if (
      EditorHelper.htmlToMarkdown(html).length <=
      CHECKLIST_DESCRIPTION_MAX_LENGTH
    )
      setDescriptionValidation(false)

    setDescription(EditorHelper.htmlToMarkdown(html))
  }

  function handleKeyCommand(
    command: EditorCommand,
    editorState: EditorState
  ): DraftHandleValue {
    switch (command) {
      case "save":
        handleSave()
        return "handled"
      // TODO: use something other than "move-down"?
      case "move-down":
        onCancel()
        return "handled"
      default:
        return "not-handled"
    }
  }

  /**
   * Handle our custom actions by returning a command that
   * handleKeyCommand will then use to trigger the associated actions.
   */
  function keyBindingFn(editorState: EditorState) {
    return (e: React.KeyboardEvent<HTMLInputElement>): EditorCommand | void => {
      const key = e.key.toLowerCase()
      switch (key) {
        case "enter":
          return "save"
        case "escape":
          onCancel()
          // break
          return "move-down"
        default:
          break
      }
    }
  }

  const runDescription = new MarkdownHelper(run?.description)

  return (
    <div className="flex flex-col">
      <label className="mb-2 font-bold" htmlFor="checklist-run-description">
        Description
      </label>
      <Editor
        cursorAtEnd
        defaultValue={(runDescription as ChecklistDescription)?.html}
        editorKey="checklist-run-description"
        focused
        keyBindingFn={keyBindingFn}
        maxLimit={CHECKLIST_DESCRIPTION_MAX_LENGTH}
        onKeyCommand={handleKeyCommand}
        setContent={handleSetContent}
        size="lg"
        style={{ fontSize: "0.875rem", paddingBottom: "1rem" }}
      />
      {descriptionValidation && (
        <p className="text-xs text-red-500">
          Description should not be more than {CHECKLIST_DESCRIPTION_MAX_LENGTH}{" "}
          characters.
        </p>
      )}

      <div className="flex flex-row items-center my-3">
        <div>
          <Button onClick={handleSave} variant="secondary">
            Save
          </Button>
        </div>
        <div className="ml-auto">
          <Button color="danger" onClick={onCancel} variant="link">
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}

function DescriptionSaving({ run }: { run: ChecklistRun }): JSX.Element {
  return (
    <div className="flex items-center">
      <ChecklistDescriptionDisplay description={run.description} />
      <Icon className="ml-4 spin" name="LoaderCircle" size="sm" />
    </div>
  )
}
