import React from "react"
import { Button, Icon, IconButtonProps, Modal } from "~src/components"
import { Alert } from "~src/components/Alert"
import { ModalProps } from "~src/components/Modal"
import { useQrCode } from "~src/hooks"

type CopyIconSubT = Pick<IconButtonProps, "icon" | "variant">

const defaultIconState: CopyIconSubT = {
  icon: "Clipboard",
  variant: "secondary",
}

export interface QrCodeModalProps {
  onClose: ModalProps["onClose"]
  url: string
  title?: string
  description?: string
  checklistTitle?: string
}

export function QrCodeModal({
  onClose,
  url,
  title = "QR code for this checklist",
  description = `Use this QR code, and each time it is scanned by any public user, it
        will take them to the current active checklists (if they exist). If not,
        it will inform them if/when the next checklist is scheduled.`,
  checklistTitle,
}: QrCodeModalProps): JSX.Element {
  const { data, error, isLoading } = useQrCode(url)
  const [copyIconSub, setCopyIconSub] = React.useState<CopyIconSubT>(
    defaultIconState
  )

  const copyHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    navigator.clipboard
      .writeText(url)
      .then((value) => {
        setCopyIconSub({ icon: "Check", variant: "primary" })
      })
      .catch((e) => {})
      .finally(() => {
        setTimeout(() => {
          setCopyIconSub(defaultIconState)
        }, 2000)
      })
  }

  return (
    <Modal
      copyBtnProps={{ ...copyIconSub, onClick: copyHandler }}
      config={{ showCloseButton: false }}
      onClose={onClose}
      title={title}
    >
      <p className="text-sm text-gray-500">{description}</p>
      {isLoading && (
        <div className="flex flex-col items-center justify-center my-12">
          <Icon className="mb-8 spin" color="muted" name="Loader" size="4x" />
          <div className="text-sm italic text-gray-600">
            Generating QR code...
          </div>
        </div>
      )}
      {error && <Alert className="my-6">{error.toString()}</Alert>}
      {data && (
        <div className="flex flex-col items-center">
          <img alt="QR code" className="mx-auto" src={data} title={url} />
          <div className="mx-auto mt-6 mb-2">
            <Button
              download={`${checklistTitle}.png`}
              href={data}
              leftIcon="Download"
              size="lg"
              target="_blank"
            >
              Download
            </Button>
          </div>
        </div>
      )}
    </Modal>
  )
}
