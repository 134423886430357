/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { useClickAway } from "react-use"
import { Router, useRouter } from "next/router"
import { Button, Icon } from "~src/components"
import { routes } from "~src/routes"
import { EditScheduleForm } from "src/components"

interface Props {
  isOwner?: boolean
  onCopy?: () => Promise<void>
  onShare?: () => void
  onSchedule?: () => void
  onChecklist?: () => void
}

export default function EllipsissDropdown({
  isOwner = false,
  onCopy,
  onShare,
  onSchedule,
  onChecklist,
}: Props): JSX.Element {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [tab, setTab] = React.useState<string>("")

  const ref = React.useRef<HTMLDivElement>(null)
  const router = useRouter()

  function handleIsOpen() {
    setIsOpen(!isOpen)
  }

  function handleCopyAndClose() {
    onCopy()
    setIsOpen(false)
  }

  useClickAway(ref, (e) => {
    if (!isOpen) return
    setIsOpen(false)
  })

  const handleChecklist = () => {
    if (router.asPath.includes("checklists")) {
      onChecklist()
      return
    }

    router.push(
      `${routes.checklists.runs(
        router.query.slug as string,
        router.query.username as string
      )}?tab=true`
    )
  }

  const handleSchedule = () => {
    if (router.asPath.includes("schedule")) {
      onSchedule()
      return
    }

    router.push(
      `${routes.checklists.schedule(
        router.query.slug as string,
        router.query.username as string
      )}?tab=true`
    )
  }

  React.useEffect(() => {
    setTab(router?.pathname?.split("/")?.pop())
  }, [router])

  return (
    <div className="relative h-max-content" ref={ref}>
      <div className="flex items-center">
        <Button
          className="inline text-sm h-max-content w-max-content"
          onClick={handleIsOpen}
          tooltip="Quick actions"
          variant="secondary"
        >
          <Icon className="m-auto" name="Ellipsis" />
        </Button>
      </div>

      {isOpen && (
        <div
          className="absolute shadow-lg rounded-md border !border-gray-300 max-h-72 max-w-auto overflow-y-auto  lg:w-auto w-72 sm:w-80 md:w-96 z-50 !bg-white"
          style={{
            top: 10,
            right: -80,
          }}
        >
          <ul className="lg:w-80 sm:w-48 md:w-48  text-xs font-light text-gray-900 !bg-white rounded-lg border !border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
            {/* <li className="w-full px-4 py-2 border-b border-gray-200 rounded-t-lg text-neutral-400 dark:border-gray-600">
              <button
                className="block px-4 py-2 text-blue-600 focus:outline-none hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={handleChecklist}
              >
                <Icon className="mr-3 text-gray-600" name="check" />
                Create New Checklist
              </button>
            </li> */}

            {/* {isOwner ? (
              <li className="w-full px-4 py-2 border-b border-gray-200 dark:border-gray-600">
                <button
                  className="block px-4 py-2 text-blue-600 focus:outline-none hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={handleSchedule}
                >
                  <Icon className="mr-3 text-gray-600" name="calendar-check" />
                  Create New Scheduled Checklist
                </button>
              </li>
            ) : null} */}
            <li className="py-2 px-4 w-full border-b !border-gray-200 dark:border-gray-600">
              <button
                className="block py-2 px-4 w-full text-start focus:outline-none hover:!bg-gray-100 dark:hover:bg-gray-600 !text-blue-600 dark:hover:text-white"
                onClick={handleCopyAndClose}
              >
                <Icon className="mr-3 !text-gray-600" name="Copy" />
                Make a Copy of this Checklist
              </button>
            </li>
            <li className="py-2 px-4 w-full border-b !border-gray-200 dark:border-gray-600">
              <button
                className="block py-2 px-4 w-full text-start  focus:outline-none hover:!bg-gray-100 dark:hover:bg-gray-600 !text-blue-600 dark:hover:text-white"
                onClick={onShare}
              >
                <Icon className="mr-3 text-gray-600" name="QrCode" />
                {tab === "schedule"
                  ? "Share QR Code for this Schedule"
                  : "Share this Checklist"}
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}
