import React from "react"
import router from "next/router"
import { Button, Icon, Modal, Loading } from "~src/components"
import { RunService } from "~src/services"
import { useActions, useState } from "~src/store"
import { checklistTemplateContext } from "~src/contextStores"
import { routes } from "~src/routes"
import {
  INITIAL_RUN_KEY,
  MODAL_TYPES,
  RUN_TYPE_INITIAL,
} from "~src/common/constants"
import { generateSlug } from "@bonsaichecklist/bonsai-utils"
import { useShowAuthModals } from "~src/hooks/useShowAuthModals"

export interface AboutToCreateRunModalProps {
  checklistSlug: string
  checkedItemSlug?: string
  onClose: () => void
  setIsRunZeroInitiated?: (param: boolean) => void
}

export interface initialRunInterface {
  checklist?: Checklist
  checkedItems: GenericObject
  run: {
    slug?: string
    runType?: string
    title?: string
    description?: string
    items?: ChecklistItem[]
    local?: boolean
    startedAt: Date
    updatedAt: Date
    finishedAt: Date | void
  }
}

const brickData = [
  {
    title: "SHARE IT",
    desc: (
      <p>
        Runs are private by default. Share the <strong>URL</strong> or the{" "}
        <strong>QR code </strong>
        so other people can use this run.
      </p>
    ),
    icon: "user",
  },
  {
    title: "Schedule IT",
    desc: (
      <p>
        You can create recurring <strong>scheduled runs</strong>. You will
        receive an e-mail whenever a new run is automatically created.
      </p>
    ),
    icon: "calendar-check",
  },
]

interface BrickProps {
  icon?: any
  title: string
  desc: JSX.Element
}

// eslint-disable-next-line react/function-component-definition
const Brick: React.FC<BrickProps> = ({ icon, desc, title }) => {
  return (
    <div className={"flex flex-col text-center justify-center p-10"}>
      <Icon
        className="self-center my-2"
        color={"#4294DD"}
        name={icon}
        size={"2x"}
      />
      <p className={"text-gray-500"}>{title}</p>
      {desc}
    </div>
  )
}

export function AboutToCreateRunModal({
  checklistSlug,
  checkedItemSlug,
  onClose,
  setIsRunZeroInitiated,
}: AboutToCreateRunModalProps): JSX.Element {
  const {
    auth: { user },
  } = useState()

  const {
    auth: { setIsRunZeroPrompted },
    checklist: { createChklRun },
  } = useActions()

  const { handleModal } = useShowAuthModals()

  const [creatingRun, setCreatingRun] = React.useState<boolean>(false)
  const { checklist, checkedItems, checklistItems } = React.useContext(
    checklistTemplateContext
  )

  async function handleCreatInitialRun() {
    setCreatingRun(true)

    if (!user && localStorage.getItem(INITIAL_RUN_KEY)) {
      onClose()

      handleModal(MODAL_TYPES.login)

      return
    }

    if (user) {
      createChklRun({ chklSlug: checklistSlug, itemSlug: checkedItems.slug })
    } else {
      const currentCheckedItem = { [checkedItems.slug]: checkedItems.slug }
      const initialRunData: initialRunInterface = {
        checkedItems: currentCheckedItem,
        checklist: checklist,
        run: {
          slug: generateSlug(),
          runType: "checklist",
          title: checklist.title,
          description: checklist.description,
          local: true,
          items: checklist.items,
          startedAt: new Date(),
          updatedAt: new Date(),
          ...(Object.keys(currentCheckedItem).length ===
            checklistItems.length && {
            finishedAt: new Date(),
          }),
        },
      }

      localStorage.setItem(INITIAL_RUN_KEY, JSON.stringify(initialRunData))
      router.push(routes.runs.show(initialRunData.run.slug))
    }

    setIsRunZeroInitiated(true)
    onClose()
  }
  return creatingRun ? (
    <Modal
      config={{
        showCloseButton: false,
        width: "3xl",
      }}
      onClose={onClose}
      title=""
    >
      <div className={"p-10 text-center"}>
        <p className="mb-2 text-2xl font-bold">Creating new run...</p>
        <Loading />
      </div>
    </Modal>
  ) : (
    <Modal
      config={{
        showCloseButton: false,
        width: "3xl",
      }}
      onClose={onClose}
      title="You are about to create a run"
    >
      <p className="mb-6 text-sm text-gray-600">
        A run is unique instance of a template that you can use.
      </p>

      <>
        <div
          className={
            "flex flex-row flex-wrap sm:flex-nowrap border-2 rounded-md"
          }
        >
          {brickData.map((item, index) => (
            <Brick
              desc={item.desc}
              icon={item.icon}
              key={index}
              title={item.title}
            />
          ))}
        </div>

        <div className={"flex flex-row justify-end my-5"}>
          <Button
            className={"mx-2"}
            disabled={creatingRun}
            onClick={onClose}
            variant={"secondary"}
          >
            Cancel
          </Button>
          <Button
            className={"mx-2"}
            disabled={creatingRun}
            onClick={handleCreatInitialRun}
          >
            Create run
          </Button>
        </div>
      </>
    </Modal>
  )
}
