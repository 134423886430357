/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { useClickAway } from "react-use"
import { Button, Icon } from "~src/components"

export interface ScheduleCardEllipsisdropdownProps {
  onDelete: () => void
}

export function ScheduleCardEllipsisdropdown({
  onDelete,
}: ScheduleCardEllipsisdropdownProps): JSX.Element {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const ref = React.useRef<HTMLDivElement>(null)

  function handleIsOpen() {
    setIsOpen(!isOpen)
  }

  useClickAway(ref, (e) => {
    if (!isOpen) return
    setIsOpen(false)
  })

  return (
    <div className="relative h-max-content" ref={ref}>
      <Button
        className="text-sm h-max-content w-max-content md:inline"
        onClick={handleIsOpen}
        tooltip="Quick actions"
        variant="link"
      >
        <Icon
          className={`m-auto ${isOpen || "text-gray-400"}`}
          size="2x"
          name="Ellipsis"
        />
      </Button>

      {isOpen && (
        <div
          className="absolute z-50 overflow-y-auto bg-white border border-gray-300 rounded-md shadow-lg max-h-72 max-w-auto lg:w-auto"
          style={{
            top: 32,
            left: 15,
          }}
        >
          <ul className="text-xs font-light text-gray-900 bg-white border border-gray-200 rounded-lg md:w-72 dark:bg-gray-700 dark:border-gray-600 dark:text-white">
            <li className="w-full px-4 py-2 border-b border-gray-200 dark:border-gray-600">
              <button
                className="flex flex-row items-center block px-2 py-2 text-blue-600 md:px-4 focus:outline-none hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white "
                onClick={onDelete}
              >
                <Icon className="m-0 text-red-400 md:mr-3" name="Trash" />
                <p className="hidden md:inline">Delete Scheduled Checklist</p>
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}
