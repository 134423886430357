import { useRouter } from "next/router"
import React from "react"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { TouchBackend } from "react-dnd-touch-backend"

import {
  NEW_CHECKLIST_SLUG,
  UNTITLED_CHECKLIST_NAME,
} from "~src/common/constants"
import {
  ChecklistHelper,
  RuntimeHelper,
  inputClassNames,
} from "~src/common/lib"
import { useStore } from "~src/store"
import { Container } from "../Container"

import sparklesIcon from "~public/icons/sparkles.svg"
import {
  Alert,
  Button,
  ChecklistField,
  Icon,
  IconButton,
  ItemDragPreview,
  Link,
  useMobileDetect,
} from "~src/components"
import { routes } from "~src/routes"
import { ChecklistService } from "~src/services"
import { KeyboardShortcutModal } from "./KeyboardShortcutModel"
import { ListItem } from "./ListItems"
import { SideBySide } from "./SideBySide"
import { VisibilitySelector } from "./VisibilitySelector"
import DescriptionField from "../DescriptionInput/DescriptionField"

const BACKEND = RuntimeHelper.supportsTouch() ? TouchBackend : HTML5Backend

export function NewEditPage(): JSX.Element {
  const router = useRouter()

  const titleRef = React.createRef<HTMLInputElement>()
  const descriptionRef = React.createRef<HTMLInputElement>()

  const [showKeyboardShortcuts, setShowKeyboardShortcuts] = React.useState<
    boolean
  >(false)
  const [isCopyingItems, setIsCopyingItems] = React.useState<boolean>(false)

  // Ai releated
  const [isGenerating, setIsGenerating] = React.useState(false)
  const [showAiButton, setShowAiButton] = React.useState(false)
  const [showAiSuccessAlert, setShowAiSuccessAlert] = React.useState(false)
  const [isTryingAgain, setIsTryingAgain] = React.useState(false)
  const [isRemoved, setIsRemoved] = React.useState(true)
  const [aiItems, setAiItem] = React.useState([])
  const [threeAiItems, setThreeAiItem] = React.useState([])
  const [loadingThreeItems, setLoadingThreeItems] = React.useState(false)
  const isMobile = useMobileDetect()
  const [isEmpty, setIsEmpty] = React.useState(false)

  const {
    actions: {
      checklist: {
        focusTitle,
        focusDescription,
        focusItem,
        focusItemWithDelay,
        insertItem,
        setUnsavedChanges,
        setTitle,
        setDescription,
        setSaving,
        save,
        deleteItem,
      },
      system: { showToastAlert, setSideBySide },
    },
    state: {
      checklist: {
        current: checklist,
        focusedTitle,
        hasItems,
        saving,
        itemMap,
        firstItem,
      },
    },
  } = useStore()

  React.useEffect(() => {
    if (!hasItems && !saving) {
      focusTitle()
      setShowAiButton(true)
    }

    if (hasItems) {
      focusDescription()
      setShowAiButton(false)
    }
  }, [focusDescription, focusTitle, hasItems, saving])

  React.useEffect(() => {
    if (focusedTitle) {
      titleRef?.current?.focus()
      titleRef?.current?.select()
    }
  }, [focusedTitle])

  function handleTitleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setUnsavedChanges(true)
    setShowAiButton(true)
    if (e.currentTarget.value === "") {
      setIsEmpty(true)
    } else {
      setIsEmpty(false)
    }
    setTitle(e.currentTarget.value)
  }

  function handleFocusName(): void {
    focusTitle()
    setShowAiButton(true)
  }

  function handleTitleKeyDown(
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void {
    const key = e.key.toLowerCase()
    switch (key) {
      case "enter":
      case "arrowdown":
        handleDescriptionFocus()
        setShowAiButton(false)
        break

      default:
        break
    }
  }

  function handleDescriptionFocus() {
    descriptionRef?.current?.focus()
    focusDescription()
  }

  function handleDescriptionChange(html: string): void {
    setUnsavedChanges(true)

    setDescription(html)
  }

  function handleDescriptionKeyDown(
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void {
    const key = e.key.toLowerCase()
    if (key === "enter") {
      const firstItemSlug = (checklist?.unscheduleItems || [])[0]
      const firstItem = itemMap[firstItemSlug]

      if (firstItem && firstItem?.body) {
        focusItemWithDelay(firstItem.slug)
      } else {
        const newItem = insertItem({ value: "", type: "unScheduled" })
        focusItemWithDelay(newItem.slug)
      }
    }
  }

  const inputClasses = inputClassNames({})

  async function handleCancel(): Promise<void> {
    const helper = new ChecklistHelper(checklist)
    const chklOwner = helper.ownerUsername
    if (checklist.slug === NEW_CHECKLIST_SLUG) {
      router.push(routes.generic.yourChecklists())
      return
    }
    const href = routes.checklists.show(checklist.slug, chklOwner)
    router.push(href)
  }

  async function handleSave(): Promise<void> {
    try {
      setSaving(true)
      setShowAiButton(false)
      Object.keys(itemMap).forEach((slug: string) => {
        if (!itemMap[slug]?.body?.trim()) {
          deleteItem(slug)
        }
      })

      const chkl = await save()
      const helper = new ChecklistHelper(chkl)
      const chklOwner = helper.ownerUsername

      const href = routes.checklists.show(chkl.slug, chklOwner)
      await router.push(href)
      setSaving(false)
    } catch (error) {
      showToastAlert({
        text: error.message,
        autoHide: true,
        type: "danger",
      })
      setUnsavedChanges(false)
    } finally {
      setSaving(false)
    }
  }

  function handleShowKeyboardShortcuts(): void {
    setShowKeyboardShortcuts(true)
  }

  function handleHideKeyboardShortcuts(): void {
    setShowKeyboardShortcuts(false)
  }

  //handle ai generation
  async function handleAiGeneratedItem(): Promise<void> {
    if (
      !checklist?.title ||
      checklist?.title.trim() === UNTITLED_CHECKLIST_NAME
    ) {
      alert("Please update checklist title first.")
      return
    }

    setIsGenerating(true)
    try {
      const items = await ChecklistService.getAiGeneratedChecklist(
        `${checklist?.title} ${
          checklist?.description && checklist.description
        }`,
        "short checklist items for"
      )

      setAiItem([
        ...aiItems,
        ...items.map((item: string) => {
          return insertItem({ value: item, type: "unScheduled" })
        }),
      ])
      setIsGenerating(false)
      setShowAiButton(false)
      setShowAiSuccessAlert(true)
      setIsTryingAgain(false)
      setIsRemoved(true)
    } catch (error) {
      // error
      setIsGenerating(false)
      setIsTryingAgain(false)
      console.log(error)
    }
  }

  async function handleThreeAiGeneratedItem(): Promise<void> {
    setIsGenerating(true)
    setLoadingThreeItems(true)

    try {
      if (firstItem.body === "") {
        deleteItem(firstItem.slug)
      }
      const previousText = checklist?.items?.map((item) => item?.body)
      const items = await ChecklistService.getAiGeneratedChecklist(
        previousText.length > 4
          ? previousText.slice(-3).toString() + "generate 3 checklist items"
          : `${checklist?.title} ${
              checklist?.description && checklist.description
            }` +
              previousText.toString() +
              "generate 3 checklist items"
      )
      setThreeAiItem([
        ...threeAiItems,
        ...items.map((item: string) => {
          return insertItem({ value: item, type: "unScheduled" })
        }),
      ])
      setIsGenerating(false)
      setShowAiButton(false)
      setShowAiSuccessAlert(false)
      setIsTryingAgain(false)
      setIsRemoved(true)
      setLoadingThreeItems(false)
    } catch (error) {
      // error
      setIsGenerating(false)
      setLoadingThreeItems(false)
      setIsTryingAgain(false)
      console.log(error)
    }
  }
  const handleRemoveAiItems = () => {
    setIsRemoved(false)
    if (aiItems?.length) {
      aiItems && aiItems?.map((item) => deleteItem(item.slug))
    } else {
      threeAiItems?.map((item) => deleteItem(item.slug))
      setShowAiSuccessAlert(false)
    }
  }

  const handleTryAgain = () => {
    setIsTryingAgain(true)
    handleRemoveAiItems()
    handleAiGeneratedItem()
  }

  return (
    <>
      <section className="bg-white border-b border-gray4">
        <Container width="7xl">
          <div className="max-w-3xl pb-12 pt-14 ">
            <h1 className="text-2xl font-bold text-black ">
              {checklist?.slug === NEW_CHECKLIST_SLUG
                ? "Create new checklist"
                : "Edit your template"}
            </h1>

            {showAiSuccessAlert && (
              <Alert className="mb-6 border " keepOpen type="ai-success">
                <div>
                  {isTryingAgain ? (
                    <strong>Generating...</strong>
                  ) : (
                    <>
                      <strong>AI assisted checklist</strong>
                      <p>This checklist has been created using AI.</p>
                    </>
                  )}
                  <div className="mt-2">
                    <IconButton
                      className="inline-block mr-3"
                      disabled={isTryingAgain}
                      icon="Undo"
                      iconStyles="mr-1"
                      onClick={handleTryAgain}
                      size="sm"
                      variant="link"
                    >
                      Try Again
                    </IconButton>
                    {isRemoved && (
                      <IconButton
                        className="inline-block mr-3"
                        icon="Trash"
                        iconStyles="mr-1"
                        onClick={handleRemoveAiItems}
                        size="sm"
                        variant="link"
                      >
                        Remove AI suggested items
                      </IconButton>
                    )}
                  </div>
                </div>
              </Alert>
            )}
            <div className="my-10">
              <div className="flex flex-col">
                <label className="mb-2 text-sm font-bold" htmlFor={"title"}>
                  Title
                </label>
                {showAiButton && (
                  <div className="relative">
                    <Button
                      className={`absolute ${
                        isMobile && "top-14 right-0"
                      } top-1.5 right-4 bg-secondaryGray text-primaryBlue border-none shadow-none`}
                      disabled={isGenerating || isEmpty}
                      onClick={handleAiGeneratedItem}
                      variant="secondary"
                    >
                      {isGenerating ? (
                        <>
                          <Icon
                            className="mr-2 text-secondaryGreen spin"
                            name="Sparkles"
                          />
                          Generating...
                        </>
                      ) : (
                        <>
                          <img
                            alt="Sparkles"
                            className="mr-2"
                            src={sparklesIcon}
                          />
                          Ai generate checklist
                        </>
                      )}
                    </Button>
                  </div>
                )}
                <input
                  className={`${inputClasses} h-11`}
                  id="title"
                  onChange={handleTitleChange}
                  onFocus={handleFocusName}
                  onKeyDown={handleTitleKeyDown}
                  ref={titleRef}
                  value={checklist?.title || ""}
                />
              </div>
            </div>

            <div>
              <label className="mb-2 text-sm font-bold" htmlFor={"description"}>
                Description
              </label>

              <DescriptionField
                ref={descriptionRef}
                value={checklist?.description || ""}
                id="description"
                placeholder="Add description here"
                toolbarVariant="title"
                handleSetContent={handleDescriptionChange}
              />
            </div>
          </div>
        </Container>
      </section>

      <Container className="mt-12 " width="7xl">
        <DndProvider backend={BACKEND} options={{ enableMouseEvents: true }}>
          <div
            className={
              isCopyingItems ? "grid grid-cols-2 gap-x-6" : "max-w-3xl"
            }
          >
            <div>
              <ListItem
                handleGenerateItems={handleThreeAiGeneratedItem}
                loading={loadingThreeItems}
              />

              <ItemDragPreview />

              <div className="hidden p-6 mt-12 border rounded-md xl:flex xl:justify-between border-gray4">
                <div>
                  <h2 className="font-bold text-md">
                    Copy list items from other checklists
                  </h2>
                  <p className="mt-2 text-sm text-black">
                    Using <b>Side by Side</b>, you can copy list items from any
                    public checklist to this one
                  </p>
                </div>
                <div>
                  <Link
                    onClick={() => {
                      setSideBySide(true)
                      setIsCopyingItems(true)
                    }}
                  >
                    Copy List Items
                  </Link>
                </div>
              </div>

              <VisibilitySelector />

              <div>
                <div className="flex justify-end gap-3 mt-12 sm:flex-row sm:flex-wrap flex-nowrap">
                  <Button
                    className="w-full ml-0 sm:w-auto sm:ml-8 md:mr-2"
                    onClick={handleCancel}
                    size="md"
                    variant="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="w-full sm:w-auto"
                    disabled={saving}
                    onClick={handleSave}
                    size="md"
                  >
                    <Icon className="mr-2" name="Check" />
                    {saving ? "Saving..." : "Save"}
                  </Button>
                </div>
                <div className="flex items-center justify-center mt-12 mb-4 text-center sm:text-right intro-keyboard-shortcuts">
                  <Button
                    leftIcon="CircleHelp"
                    muted
                    onClick={handleShowKeyboardShortcuts}
                    size="sm"
                    variant="link"
                  >
                    Keyboard Shortcuts
                  </Button>
                  {showKeyboardShortcuts && (
                    <KeyboardShortcutModal
                      onClose={handleHideKeyboardShortcuts}
                    />
                  )}
                </div>
              </div>
            </div>
            {isCopyingItems && (
              <SideBySide setIsCopyingItems={setIsCopyingItems} />
            )}
          </div>
        </DndProvider>
      </Container>
    </>
  )
}
