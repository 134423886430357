import React, { Fragment } from "react"
import { ChecklistItemsHelper } from "@bonsaichecklist/bonsai-utils"
import { Button, ScheduleItem, Loading } from "~src/components"
import { useStore } from "~src/store"
import { Icon } from "../Icon"

export interface ScheduleConfirmDialogProps {
  checklist: Checklist
  action: "schedule-copy" | "subscribe"
  showSeeMore?: boolean
  schedules: Schedule[]
  handleConfirm?: () => void
  handleCopyConfirm?: (copySchedules: boolean) => void
}

export function ScheduleConfirmDialog({
  checklist,
  action,
  showSeeMore = false,
  handleConfirm,
  handleCopyConfirm,
  ...props
}: ScheduleConfirmDialogProps): JSX.Element {
  const {
    actions: {
      checklist: { setScheduleConfirmDialog },
    },
    state: {
      checklist: { showScheduleDialogue },
    },
  } = useStore()

  const [schedules, setSchedules] = React.useState<Schedule[]>([
    props.schedules[0],
  ])

  const chklItemMap = checklist
    ? ChecklistItemsHelper.makeItemMap(checklist.items)
    : null

  if (!showScheduleDialogue) return null

  function handleSeeMore(): void {
    const nextSched = props.schedules[schedules.length]
    if (nextSched) {
      setSchedules([...schedules, nextSched])
    }
  }

  const seeMoreVisible =
    schedules.length !== props.schedules.length && showSeeMore

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center m-0 overflow-auto sm:m-8">
        <div className="relative items-center justify-center ml-0">
          {/*content*/}
          <div
            className="relative flex flex-col max-w-lg p-8 bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none"
            style={{ height: "auto", maxHeight: 640 }}
          >
            {/*header*/}
            <div className="flex items-start justify-end">
              <h3 className="flex text-lg font-bold">
                {action === "schedule-copy"
                  ? "Copy with scheduled items?"
                  : "Confirm Subscription"}
                <span className="flex ml-2 text-xs text-gray-500 align-top">
                  <Icon
                    name="Info"
                    title="Lists and list items can be scheduled to run on a specific time"
                  />
                </span>
              </h3>
              <button
                className="px-2 ml-auto text-lg border-0 rounded hover:bg-gray-300"
                onClick={() => setScheduleConfirmDialog(false)}
              >
                ×
              </button>
            </div>
            {schedules.length > 0 && (
              <>
                {action === "schedule-copy" ? (
                  <p className="flex mt-4 mb-8 item-center">
                    This checklist has scheduled items attached to it. Do you
                    want to copy them to your checklist copy?
                  </p>
                ) : (
                  <p className="flex mt-4 mb-8 item-center">
                    you will subscribe to following schedules:
                  </p>
                )}
              </>
            )}
            {/*body*/}
            <div className="flex flex-col w-full p-6 mb-6 overflow-y-auto border border-gray-300 rounded-md md:w-full">
              {schedules.length === 0 && <Loading />}
              <div>
                {schedules.map((schedule, index) => (
                  <Fragment key={index}>
                    <ScheduleItem
                      checklist={checklist}
                      chklItemMap={chklItemMap}
                      key={schedule.id}
                      schedule={schedule}
                      showActions={false}
                      showDivider={schedules.length - 1 !== index}
                      showItems={false}
                    />
                  </Fragment>
                ))}
              </div>
              {seeMoreVisible && (
                <div className="flex justify-center mt-8 text-blue-400">
                  <button onClick={handleSeeMore}>See more</button>
                </div>
              )}
            </div>
            {schedules.length > 0 && (
              <div className="bg-gray-50 sm:flex sm:flex-row-reverse">
                <Button
                  className="w-full sm:w-auto"
                  onClick={
                    action === "schedule-copy"
                      ? () => handleCopyConfirm(true)
                      : handleConfirm
                  }
                >
                  {action === "schedule-copy"
                    ? "Copy with schedule"
                    : "Subscribe"}
                </Button>
                <Button
                  className="w-full mt-4 sm:w-auto sm:mt-0 sm:mr-4"
                  onClick={
                    action === "schedule-copy"
                      ? () => handleCopyConfirm(false)
                      : () => setScheduleConfirmDialog(false)
                  }
                  variant="secondary"
                >
                  {action === "schedule-copy"
                    ? "Copy without schedule"
                    : "Cancel"}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
    </>
  )
}
