import { AnimatePresence, motion } from "framer-motion"
import { useRouter } from "next/router"
import React from "react"
import * as yup from "yup"
import {
  Alert,
  Button,
  Form,
  GoogleLoginButton,
  Input,
  Link,
  OtpInput,
} from "~src/components"
import { getQueryValue } from "~src/common/lib"
import { routes } from "~src/routes"
import { useStore } from "~src/store"
import { useShowAuthModals } from "~src/hooks/useShowAuthModals"
import { MODAL_TYPES } from "~src/common/constants"
import { AuthService } from "~src/services"
import Markverified from "~public/mark-verified.svg"
import { AppleLoginButton } from "../../AppleLoginButton/AppleLoginButton"

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
})

export function LoginForm(): JSX.Element {
  const router = useRouter()
  const { handleModal } = useShowAuthModals()
  const emailInputRef = React.useRef({ email: "" })

  const [otp, setOtp] = React.useState<string>("")
  const [isEmailVerified, setIsEmailVerified] = React.useState<boolean>(false)
  const [errorMsg, setErrorMsg] = React.useState()
  const onChange = (value: string) => setOtp(value)

  const [loggingIn, setLoggingIn] = React.useState<boolean>(false)
  const {
    actions: {
      auth: { resetError, setTempAuthResponse },
    },
    state: {
      auth: { error, action },
    },
  } = useStore()

  const redirect = getQueryValue(router.query?.redirect)

  React.useEffect(() => {
    return (): void => resetError()
  }, [resetError])

  function handleOnFocus(): void {
    resetError()
  }

  const {
    actions: {
      auth: { loginWithEmailPassword },
    },
  } = useStore()

  const handleLogin = React.useCallback(
    async ({
      email,
      password,
    }: {
      email: string
      password: string
    }): Promise<void> => {
      setLoggingIn(true)
      try {
        emailInputRef.current.email = email
        const response = await loginWithEmailPassword({ email, password })
        handleModal(MODAL_TYPES.login)

        if (router.asPath === "/" && response.runActivityExists) {
          router.push(routes.generic.dashboardView())
        }

        if (router.asPath === "/" && !response.runActivityExists) {
          router.push(routes.generic.community())
        }
      } catch (e) {
        console.error(e)
      } finally {
        setLoggingIn(false)
      }
    },
    [redirect, router, loginWithEmailPassword, setLoggingIn]
  )

  const verifyOtp = async () => {
    try {
      const response = await AuthService.verifyEmail(otp)
      if (response) {
        setTempAuthResponse(response)
        setIsEmailVerified(true)
      }
    } catch (error) {
      setErrorMsg(error)
    }
  }

  const handleResendOtp = async () => {
    try {
      await AuthService.resendEmailToken(emailInputRef.current.email)
    } catch (error) {
      throw new Error(error)
    }
  }

  if (isEmailVerified) {
    return (
      <div className="flex flex-col items-center ">
        <img alt="Mark-Verified" src={Markverified} />
        <p className="mt-5 text-xl font-bold text-black ">
          Your e-mail has been verified
        </p>
        <p className="mt-2 text-sm font-normal text-gray-900">
          Welcome to ChainList
        </p>
        <Button
          className="text-sm font-normal mt-9"
          onClick={() => {
            handleModal(MODAL_TYPES.login)
            if (router.asPath === "/") {
              router.push(routes.generic.community())
            }
          }}
        >
          Let’s get started
        </Button>
      </div>
    )
  }

  return (
    <>
      <AnimatePresence>
        {error && (
          <motion.div
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
          >
            <Alert className="my-6" keepOpen>
              {error.toString()}
            </Alert>
          </motion.div>
        )}
      </AnimatePresence>
      {error?.message?.includes("OTP") ? (
        <OtpInput
          errorMsg={errorMsg}
          onChange={onChange}
          resendOtp={handleResendOtp}
          setErrorMsg={setErrorMsg}
          value={otp}
          valueLength={6}
          verifyOtp={verifyOtp}
        />
      ) : (
        <>
          <div className="my-6 text-center">
            <GoogleLoginButton fullWidth />
          </div>
          <div className="my-6 text-center">
            <AppleLoginButton fullWidth />
          </div>
          <div className="my-6 text-center text-gray-500">OR</div>
          <Form onSubmit={handleLogin} schema={schema} values={{}}>
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
            }: any): JSX.Element => (
              <form onSubmit={handleSubmit}>
                <div className="my-3">
                  {errors?.email && (
                    <div className="mb-2 text-red-600">{errors.email}</div>
                  )}
                  <Input
                    autoCapitalize="none"
                    autoComplete="off"
                    autoCorrect="off"
                    id="email"
                    onChange={handleChange}
                    onFocus={handleOnFocus}
                    placeholder="Email address"
                    type="email"
                    value={values.email}
                  />
                </div>
                <div className="my-3">
                  {errors?.password && (
                    <div className="mb-2 text-red-600">{errors.password}</div>
                  )}
                  <Input
                    autoCapitalize="none"
                    autoCorrect="off"
                    id="password"
                    onChange={handleChange}
                    onFocus={handleOnFocus}
                    placeholder="Password"
                    type="password"
                    value={values.password}
                  />
                  <div className="my-4 text-right">
                    <Link href={routes.auth.forgotPassword()}>
                      Forgot Password?
                    </Link>
                  </div>
                </div>
                <div className="my-6">
                  <Button
                    disabled={!values.email || !values.password || loggingIn}
                    fullWidth
                    leftIcon="Lock"
                    type="submit"
                  >
                    Login
                  </Button>
                </div>
                <div className="text-sm">
                  Don&apos;t have an account?{" "}
                  <Link
                    className="text-sm"
                    onClick={() =>
                      handleModal(MODAL_TYPES.signup, { key: action?.key })
                    }
                  >
                    Sign up
                  </Link>
                </div>
              </form>
            )}
          </Form>
        </>
      )}
    </>
  )
}
