import React from "react"
import { Icon, Link } from "~src/components"

export interface BackLinkProps extends React.HTMLProps<HTMLDivElement> {
  children?: React.ReactNode
  href: string
}

export function BackLink({
  children,
  href,
  ...props
}: BackLinkProps): JSX.Element {
  return (
    <div className="flex-row items-center mb-3" {...props}>
      <Link className="text-sm" href={href}>
        <Icon className="mr-2" name="ArrowLeft" />
        {children || "Back"}
      </Link>
    </div>
  )
}
