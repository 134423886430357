import classnames from "classnames"
import React from "react"
import { upperFirst } from "~src/common/lib"
import { Icon, IconName } from "~src/components"

export interface ChecklistVisibilityIconProps {
  visibility: ChecklistVisibility
  iconColor?: "muted" | "blue"
}

const CHECKLIST_VISIBILITY_CONTENT = {
  public: {
    icon: "lock-open",
    tooltip: "Anyone can see this template",
    text: "Shared to Community",
  },
  unlisted: {
    icon: "unlock",
    tooltip: "Only those with the URL can access this template",
    text: "unlisted",
  },
  private: {
    icon: "lock",
    tooltip: "Only you can see this template",
    text: "private",
  },
}

export function ChecklistVisibilityIcon({
  visibility,
  iconColor,
}: ChecklistVisibilityIconProps): JSX.Element {
  const checklistContent = CHECKLIST_VISIBILITY_CONTENT[visibility]
  const classes = classnames("mr-2", { "text-blue-500": iconColor === "blue" })

  return (
    <p title={checklistContent?.tooltip}>
      <Icon className={classes} name={checklistContent?.icon as IconName} />
      <span>{upperFirst(checklistContent?.text)}</span>
    </p>
  )
}
