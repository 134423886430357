import React from "react"

import { Icon, ItemDropArea, onDropParams } from "~src/components"
import { ChecklistField } from "../ChecklistField"
import SubCheckIcon from "~public/icons/squareCheck.svg"
import { useStore } from "~src/store"
import { AttachmentModal } from "../AttachmentModal"
import { ScheduledOptions } from "./ScheduledOptions"
import { useDrag } from "react-dnd"
import DescriptionField from "../DescriptionInput/DescriptionField"

type ItemCardProps = {
  item: ChecklistItem
  subItems: ChecklistItem[]
  showSchedule?: boolean
  index: number
}

export function ItemCard(props: ItemCardProps): JSX.Element {
  const { item, subItems, showSchedule, index } = props

  const {
    actions: {
      checklist: { deleteItem, indent, moveDroppedItem, setItemDescription },
    },
    state: {
      checklist: { itemMap },
    },
  } = useStore()

  const [, drag] = useDrag({
    item: { ...item, type: "item" },
  })

  const [attachmentModalVisible, setAttachmentModalVisible] = React.useState<
    boolean
  >(false)

  const hasSubitems = subItems?.length > 0

  function handleShowAttachmentModal(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void {
    e.stopPropagation()
    setAttachmentModalVisible(true)
  }

  function handleHideAttachmentModal(): void {
    setAttachmentModalVisible(false)
  }

  function handleAddSubItem() {
    indent({ itemSlug: item?.slug })
  }

  function handleDropEnd(params: onDropParams): void {
    moveDroppedItem(params)
  }

  function handleItemDescriptionChange(html: string): void {
    setItemDescription({
      slug: item?.slug,
      itemDescription: html,
    })
  }

  const dropAreaBaseClasses = "absolute left-8 right-0"
  const descriptionRef = React.createRef<HTMLInputElement>()

  return (
    <div className="relative flex items-start mb-4">
      <div className="mr-5 text-xl select-none" ref={drag}>
        <Icon
          aria-label="drag handle"
          className="text-2xl text-gray-500 cursor-move hover:text-gray-800"
          name="GripVertical"
        />
      </div>

      <div
        className={dropAreaBaseClasses}
        style={{ top: "-1.1rem", zIndex: 8 }}
      >
        <ItemDropArea
          dropAreaItem={item}
          dropIndex={index}
          dropPosition="before"
          onDropEnd={handleDropEnd}
          parent={itemMap[item?.parent]}
          schedule={showSchedule}
        />
      </div>

      <div className="flex-1 p-6 bg-white border rounded-md border-gray4">
        <ChecklistField
          actions={[
            {
              icon: "Paperclip",
              alwaysShow: item?.attachments?.length > 0,
              badgeContent: item?.attachments?.length,
              title: "Manage attachments",
              action: handleShowAttachmentModal,
            },
          ]}
          item={item}
          parentItemSlug={item?.slug}
          placeholder="Add checklist item"
          shouldBold={showSchedule}
        />
        {/* <input
          className={`${inputClasses} h-11 text-xs text-black`}
          onChange={handleItemDescriptionChange}
          placeholder="Add description"
          value={item?.description || ""}
        /> */}

        <DescriptionField
          ref={descriptionRef}
          value={item?.description || ""}
          id="description"
          size="sm"
          placeholder="Add description"
          toolbarVariant="description"
          handleSetContent={handleItemDescriptionChange}
        />
        <div className="mb-8"></div>
        {showSchedule && <ScheduledOptions itemSlug={item?.slug} />}
        {hasSubitems &&
          subItems.map((subItem, index) => (
            <SubItems
              index={index}
              key={subItem.slug}
              schedule={showSchedule}
              subItem={subItem}
            />
          ))}

        <div className="absolute left-14 right-8">
          <ItemDropArea
            dropAreaItem={item}
            dropIndex={index}
            onDropEnd={handleDropEnd}
            parent={item}
            schedule={showSchedule}
          />
        </div>

        <div className="flex items-center justify-between mt-6">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={handleAddSubItem}
          >
            <img alt="Add SubItem" src={SubCheckIcon} />
            <p className="text-xs font-normal text-primaryBlue">
              Add sublist item
            </p>
          </div>

          <p
            className="text-xs font-normal text-red-600 cursor-pointer"
            onClick={() => deleteItem(item?.slug)}
          >
            Delete checklist item
          </p>
        </div>
      </div>

      <div className={dropAreaBaseClasses} style={{ bottom: "-1.25rem" }}>
        <ItemDropArea
          dropAreaItem={item}
          dropIndex={index}
          onDropEnd={handleDropEnd}
          parent={itemMap[item?.parent]}
          schedule={showSchedule}
        />
      </div>

      <div className="flex flex-row items-center text-lg text-gray-500">
        <div>
          {attachmentModalVisible && (
            <AttachmentModal item={item} onClose={handleHideAttachmentModal} />
          )}
        </div>
      </div>
    </div>
  )
}

function SubItems({
  subItem,
  index,
  schedule,
}: {
  subItem: ChecklistItem
  index: number
  schedule?: boolean
}): JSX.Element {
  const {
    actions: {
      checklist: { deleteItem, moveDroppedItem },
    },
    state: {
      checklist: { itemMap },
    },
  } = useStore()

  const [, drag] = useDrag({
    item: { ...subItem, type: "item" },
  })

  const [
    attachmentSubModalVisible,
    setAttachmentSubModalVisible,
  ] = React.useState<boolean>(false)

  function handleShowSubAttachmentModal(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void {
    e.stopPropagation()
    setAttachmentSubModalVisible(true)
  }

  function handleHideSubAttachmentModal(): void {
    setAttachmentSubModalVisible(false)
  }

  function handleDropEnd(params: onDropParams): void {
    moveDroppedItem(params)
  }

  const dropAreaBaseClasses = "absolute left-8 right-0"

  return (
    <>
      <div className="relative flex items-center mt-6">
        <div className="mr-5 text-xl select-none" ref={drag}>
          <Icon
            aria-label="drag handle"
            className="text-2xl text-gray-500 cursor-move hover:text-gray-800"
            name="GripVertical"
          />
        </div>

        <div
          className={dropAreaBaseClasses}
          style={{ top: "-1.1rem", zIndex: 8 }}
        >
          <ItemDropArea
            dropAreaItem={subItem}
            dropIndex={index}
            dropPosition="before"
            onDropEnd={handleDropEnd}
            parent={itemMap[subItem?.parent]}
            schedule={schedule}
          />
        </div>

        <div className="flex-1">
          <ChecklistField
            actions={[
              {
                icon: "Trash",
                alwaysShow: false,
                title: "Delete item",
                action: () => deleteItem(subItem.slug),
                className: "hover:text-red-700 break-words",
              },
              {
                icon: "Paperclip",
                alwaysShow: subItem?.attachments?.length > 0,
                badgeContent: subItem?.attachments?.length,
                title: "Manage attachments",
                action: handleShowSubAttachmentModal,
              },
            ]}
            item={subItem}
            parentItemSlug={subItem?.parent}
            placeholder="Add subitem"
          />
        </div>
        <div className={dropAreaBaseClasses} style={{ bottom: "-1.25rem" }}>
          <ItemDropArea
            dropAreaItem={subItem}
            dropIndex={index}
            onDropEnd={handleDropEnd}
            parent={itemMap[subItem?.parent]}
            schedule={schedule}
          />
        </div>
      </div>

      <div className="flex flex-row items-center text-lg text-gray-500">
        <div>
          {attachmentSubModalVisible && (
            <AttachmentModal
              item={subItem}
              onClose={handleHideSubAttachmentModal}
            />
          )}
        </div>
      </div>
    </>
  )
}
