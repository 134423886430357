import * as React from "react"
import { usePreview } from "react-dnd-preview"
import {
  ChecklistItemsHelper,
  getLinkedListName,
} from "@bonsaichecklist/bonsai-utils"
import { ChecklistField, DragObjectItem, Icon } from "~src/components"
import { useState } from "~src/store"
import { RuntimeHelper } from "~src/common/lib/RuntimeHelper"

interface ItemPreviewProps {
  children?: React.ReactNode
  item: ChecklistItem
  style?: React.CSSProperties
  rootItem?: ChecklistItem
}

const MAX_ITEMS_LENGTH = 2

export function ItemPreview({
  children = null,
  item,
  style,
  rootItem,
}: ItemPreviewProps): JSX.Element {
  const memoizedChildren = React.useMemo(() => children || "", [children])
  return (
    <div
      className={`${Boolean(item.parent) && "mt-2"} ml-6 w-64 opacity-75`}
      style={style}
    >
      <div className={`${Boolean(item.parent) && "pl-6"}`}>
        <>
          <div className="flex flex-row items-center w-full">
            {(RuntimeHelper.supportsTouch() ||
              (rootItem && item.id !== rootItem?.id)) && (
              <div className="text-xl">
                <Icon
                  className="mr-3 text-3xl text-gray-800 cursor-move"
                  name="Grip"
                />
              </div>
            )}
            <div className="flex-1">
              <ChecklistField item={item as ChecklistItem} />
            </div>
          </div>
        </>
        {memoizedChildren}
      </div>
    </div>
  )
}

export function ItemDragPreview(): JSX.Element | null {
  const { display, item, style } = usePreview()
  const {
    checklist: { itemMap },
  } = useState()

  if (!display) {
    return null
  }

  const subItems = ChecklistItemsHelper.getSubItemsOfParent(itemMap, item.slug)
  const isLinkedListItem = getLinkedListName(item.body)
  const hasSubItems = subItems?.length

  return (
    <ItemPreview item={item as DragObjectItem} style={style}>
      {!isLinkedListItem &&
        hasSubItems &&
        subItems
          .slice(0, MAX_ITEMS_LENGTH)
          .map((i) => <ItemPreview item={i} key={i.slug} />)}
    </ItemPreview>
  )
}
