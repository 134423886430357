import React from "react"
import * as Icons from "lucide-react"
import { type LucideIcon } from "lucide-react"

export type IconName = keyof typeof Icons
export type SizeProp = "1x" | "sm" | "lg"

export interface IconProps
  extends React.ComponentProps<Icons.LucideIcon> {
  name: IconName
  fixedWidth?: boolean
  variant?: any
  title?:string
}

export function Icon({ name, ...props }: IconProps): JSX.Element {
  const Icon = Icons[name] as LucideIcon
  return <Icon {...props} />
}
