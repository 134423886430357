import React from "react"
import { checklistTitle, getChecklistOwner } from "~src/common/lib"
import {
  Button,
  ChecklistDescriptionDisplay,
  ChecklistMetadataProps,
  Icon,
  Link,
} from "~src/components"
import { ChecklistMetadata } from "~src/components/ChecklistMetadata"
import { routes } from "~src/routes"
import { ChecklistService } from "~src/services"
import { useStore } from "~src/store"

export interface SubscribedChecklistCardProps {
  checklist: Checklist
  config?: ChecklistMetadataProps["config"]
  onSubRemoved?: () => void
}

export function SubscribedChecklistCard({
  checklist,
  config = { showVisibility: false, showOwner: true },
  onSubRemoved,
}: SubscribedChecklistCardProps): JSX.Element {
  const {
    state: {
      auth: { user },
    },
  } = useStore()

  if (!checklist) return <></>

  const { title, description, slug } = checklist

  const chklOwner = getChecklistOwner(checklist)

  async function handleUnSubOfChecklist(): Promise<void> {
    const result = await ChecklistService.unFollow(slug, btoa(user.username))
    if (result) onSubRemoved()
    return
  }

  return (
    <article className="px-6 py-6 mb-6 bg-white border rounded-md">
      <div>
        <div className={"flex justify-between flex-row"}>
          <header className="truncate">
            <h3 className="text-xl font-bold leading-tight truncate">
              <Link
                href={routes.checklists.show(checklist.slug, chklOwner)}
                title={checklistTitle(title)}
                tooltip={checklistTitle(title)}
              >
                {checklistTitle(title)}
              </Link>
            </h3>
          </header>
          <span className="flex h-max-content">
            <Button
              className="text-sm h-max-content btn--hover"
              onClick={handleUnSubOfChecklist}
              variant="secondary"
            >
              <Icon className="mr-3" name="Check" />
              Subscribed
            </Button>
            {/* <span className="text-sm btn btn--half-disabled">
              <Icon name="bell" variant="solid" />
            </span> */}
          </span>
        </div>
        {description && (
          <div className="w-4/5 text-sm text-gray-700">
            <ChecklistDescriptionDisplay description={description} textOnly />
          </div>
        )}
      </div>

      <div className="mt-4">
        <ChecklistMetadata checklist={checklist} config={config} />
      </div>
    </article>
  )
}
