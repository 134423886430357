import React from "react"
import { Icon, IconProps, Link, LinkProps } from "~src/components"
import { ToolbarProps } from "~src/components/Editor/Toolbar"
import { buttonClasses } from "~src/components/Editor/utils"

export function FormattingButtons(props: ToolbarProps): JSX.Element {
  let items = [LinkItem, UnlinkItem]
  if (props.variant === "title") {
    items = items.concat([
      BoldItem,
      ItalicItem,
      CodeItem,
      StrikethroughItem,
      ClearFormattingItem,
    ])
  }
  function handleHideToolbar(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void {
    e.preventDefault()
    props.hideToolbar()
  }

  return (
    <>
      {items.map((Component, key) => (
        <div className="mr-2" key={key}>
          <Component {...props} />
        </div>
      ))}
      <div className="ml-auto">
        <ToolbarButton
          icon="X"
          onClick={handleHideToolbar}
          title="Close editor"
        />
      </div>
    </>
  )
}

function LinkItem({
  currentLinkURL,
  hasSelectedText,
  showLinkEditor,
}: ToolbarProps): JSX.Element {
  return (
    <ToolbarButton
      active={Boolean(currentLinkURL)}
      disabled={!hasSelectedText}
      icon="Link"
      onClick={showLinkEditor}
      title="Link"
    />
  )
}

function UnlinkItem({ unlink, cursorOverLink }: ToolbarProps): JSX.Element {
  function handleClick(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void {
    e.preventDefault()
    unlink()
  }

  return (
    <ToolbarButton
      disabled={!cursorOverLink}
      icon="Unlink"
      onClick={handleClick}
      title="Remove link"
    />
  )
}

function BoldItem({
  bold,
  hasSelectedText,
  currentInlineStyle,
}: ToolbarProps): JSX.Element {
  function handleClick(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void {
    e.preventDefault()
    bold()
  }

  return (
    <ToolbarButton
      active={currentInlineStyle.has("BOLD")}
      disabled={!hasSelectedText}
      icon="Bold"
      onClick={handleClick}
      title="Bold"
    />
  )
}

function ItalicItem({
  italic,
  hasSelectedText,
  currentInlineStyle,
}: ToolbarProps): JSX.Element {
  function handleClick(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void {
    e.preventDefault()
    italic()
  }

  return (
    <ToolbarButton
      active={currentInlineStyle.has("ITALIC")}
      disabled={!hasSelectedText}
      icon="Italic"
      onClick={handleClick}
      title="Italic"
    />
  )
}

function StrikethroughItem({
  strikethrough,
  hasSelectedText,
  currentInlineStyle,
}: ToolbarProps): JSX.Element {
  function handleClick(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void {
    e.preventDefault()
    strikethrough()
  }

  return (
    <ToolbarButton
      active={currentInlineStyle.has("STRIKETHROUGH")}
      disabled={!hasSelectedText}
      icon="Strikethrough"
      onClick={handleClick}
      title="Strikethrough"
    />
  )
}

function CodeItem({
  code,
  hasSelectedText,
  currentInlineStyle,
}: ToolbarProps): JSX.Element {
  function handleClick(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void {
    e.preventDefault()
    code()
  }

  return (
    <ToolbarButton
      active={currentInlineStyle.has("CODE")}
      disabled={!hasSelectedText}
      icon="Code"
      onClick={handleClick}
      title="Code"
    />
  )
}

function ClearFormattingItem({
  clearFormatting,
  hasSelectedText,
}: ToolbarProps): JSX.Element {
  function handleClick(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void {
    e.preventDefault()
    clearFormatting()
  }

  return (
    <div className="pl-2 ml-2 border-l border-gray-300">
      <ToolbarButton
        disabled={!hasSelectedText}
        icon="RemoveFormatting"
        onClick={handleClick}
        title="Clear formatting"
      />
    </div>
  )
}

export interface ToolbarButtonProps extends Omit<LinkProps, "children"> {
  icon: IconProps["name"]
  active?: boolean
}

export function ToolbarButton({
  active,
  disabled,
  icon,
  onClick,
  title,
  ...props
}: ToolbarButtonProps): JSX.Element {
  return (
    <Link
      aria-selected={active}
      className={buttonClasses({ disabled, active })}
      disabled={disabled}
      onClick={onClick}
      title={title}
      unstyled
      {...props}
    >
      <Icon fixedWidth name={icon} />
    </Link>
  )
}
