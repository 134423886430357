import React from "react"
import { Icon } from "../Icon"
import { TailSpinLoader } from "./TailSpinLoader"
import { createApi } from "unsplash-js"
import { Button } from "../Button"
import { useDebounce } from "react-use"

interface unSplashProps {
  handleFileUrl: (url: string) => void
  error?: string
  setError?: (value: string) => void
}

const unsplash = createApi({
  accessKey: process.env.UNSPLASH_ACCESS_KEY,
})

export function Unsplash({
  handleFileUrl,
  error,
  setError,
}: unSplashProps): JSX.Element {
  const unsplashView = React.useRef<HTMLDivElement>(null)

  const [photos, setPhotos] = React.useState([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const [showLoadMore, setLoadMore] = React.useState<boolean>(false)
  const [query, setQuery] = React.useState<string>("")
  const [page, setPage] = React.useState<number>(1)
  const [splashError, setSplashError] = React.useState<string>("")

  useDebounce(fetchPhotos, 1000, [query])

  async function fetchPhotos(page = 1) {
    if (query.length < 2) {
      setSplashError("")
      setError("")
      return setPhotos([])
    }

    setLoading(true)

    try {
      const response = await unsplash.search.getPhotos({
        query,
        perPage: 9,
        page,
      })

      page > 1
        ? setPhotos([...photos, ...response.response.results])
        : setPhotos(response.response.results)
      setPage(page)

      setLoadMore(response.response.total !== response.response.results.length)
    } catch (error) {
      setSplashError(
        "There was problem getting photos. Please try again later."
      )
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (photos.length) {
      unsplashView?.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      })
    }
  }, [photos.length])

  return (
    <div className="flex flex-col items-center py-6">
      <div className="flex items-center w-11/12 h-10 border border-gray-300 rounded">
        <div className="px-4">
          <Icon className="text-primaryBlue" name="Search" />
        </div>
        <input
          className="w-full outline-none"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setQuery(e.currentTarget.value)
            setPage(1)
          }}
          placeholder="Search Unsplash"
          type="text"
          value={query}
        />
        <div className="pl-5">{loading && <TailSpinLoader />}</div>
      </div>

      {!!splashError && (
        <p className="py-1 text-xs font-normal text-center text-red-600">
          {splashError}
        </p>
      )}

      {!!error && (
        <p className="py-1 text-xs font-normal text-center text-red-600">
          {error}
        </p>
      )}

      <div className="flex flex-wrap items-center justify-center gap-3 overflow-y-scroll mt-7 max-h-72">
        {photos.map((photo, index) => (
          <div
            className={`mb-2 hover:opacity-80 relative`}
            key={photo.id}
            onClick={() => handleFileUrl(photo?.urls?.regular)}
            ref={index === photos.length - 1 ? unsplashView : null}
          >
            <img
              alt=""
              className="w-32 h-20 rounded cursor-pointer"
              src={photo?.urls?.thumb}
            />

            <div
              className={`absolute bottom-0 left-2 py-1 flex items-center gap-2 opacity-70 hover:opacity-100 `}
            >
              <a
                className="flex items-center gap-2 font-normal text-white text-2xs"
                href={`https://unsplash.com/@${photo?.user?.username}?utm_source=ChainList&utm_medium=referral`}
              >
                <img
                  alt=""
                  className="w-5 h-5 rounded-full "
                  src={photo?.user?.profile_image?.small}
                />
                {photo?.user?.name}
              </a>
            </div>
          </div>
        ))}
      </div>

      {showLoadMore && !!photos.length && (
        <Button
          className="mt-4"
          onClick={() => fetchPhotos(page + 1)}
          variant="secondary"
        >
          <span className="text-xs font-semibold">Load more</span>
        </Button>
      )}
    </div>
  )
}
