import React from "react"
import { useClickAway } from "react-use"
import dayjs from "dayjs"
import RRule from "rrule"
import { isEmpty, values, invert } from "lodash"

import { Modal, Icon, Button } from "~src/components"
import { useStore } from "~src/store"
// import { DateHelper } from "@bonsaichecklist/bonsai-utils"
// import { getNumberWithOrdinal } from "~src/common/lib"
import { weekDays, monthsFull } from "../EditScheduleForm/helpers"
import { RECURRENCE_PATTERNS } from "~src/common/lib"

type Props = {
  setShowCustomModal: (value: boolean) => void
  itemSlug: string
  setPattern: (pattern: { title: string; value: RECURRENCE_PATTERNS }) => void
}

export type CustomRepeat = "daily" | "weekly" | "monthly" | "yearly"

const customOptions: GenericObject = {
  day: "daily",
  week: "weekly",
  month: "monthly",
  year: "yearly",
}

const daysOfWeekFrequency: GenericObject = {
  "1": {
    title: "first",
    value: 1,
  },
  "2": {
    title: "second",
    value: 2,
  },
  "3": {
    title: "third",
    value: 3,
  },
  "-1": {
    title: "last",
    value: -1,
  },
}

const weekDaysDropdownOptions = Object.keys(weekDays).reduce((acc, current) => {
  acc[current] = {
    title: weekDays[current],
    value: current,
  }
  return acc
}, {} as typeof weekDays)

function CustomOptionsModal({
  setShowCustomModal,
  itemSlug,
  setPattern,
}: Props): JSX.Element {
  const {
    state: {
      checklist: { checklistScheduleMap },
    },
    actions: {
      checklist: { setCustomOptions, deleteCustomOption },
    },
  } = useStore()

  const startedAt = checklistScheduleMap[itemSlug]?.startedAt
  const startDate = dayjs(startedAt)
  const startMonthIndex = startDate.month()
  const dayIndex = startDate.day() - 1
  const customOption = checklistScheduleMap[itemSlug]?.customOptions

  // const monthRepeatOptions: GenericObject = {
  //   firstDay: {
  //     title: `Monthly on the first ${DateHelper.weekday(startedAt)}`,
  //     value: "firstDay",
  //   },
  //   monthDay: {
  //     title: `Monthly on day ${getNumberWithOrdinal(
  //       DateHelper.dayOfMonth(startedAt)
  //     )}`,
  //     value: "monthDay",
  //   },
  //   lastDay: {
  //     title: `Monthly on the last ${DateHelper.weekday(startedAt)}`,
  //     value: "lastDay",
  //   },
  // }

  const [onWeekdays, setOnWeekdays] = React.useState<GenericObject>(() => {
    const weeks = (customOption?.byweekday as Weekdays[]) || []
    const weekMap = weeks.reduce((acc, current) => {
      return { ...acc, [current]: current }
    }, {})

    return weekMap
  })
  const [customRepeat, setCustomRepeat] = React.useState<CustomRepeat>(
    customOption?.freq || "daily"
  )

  // const [monthOptions, setMonthOptions] = React.useState<{
  //   title: string
  //   value: string
  // }>(
  //   monthRepeatOptions[customOption?.monthRepeat?.value] ||
  //     monthRepeatOptions["firstDay"]
  // )

  const weekDay = customOption?.byweekday
    ? (customOption?.byweekday as SingleWeekDay[])[0]
    : { weekday: dayIndex, n: 1 }

  const [weekDayFrequency, setWeekDayFrequency] = React.useState(
    weekDay.n.toString()
  )
  const [dayOfWeek, setDayOfWeek] = React.useState(
    Object.keys(weekDays)[weekDay.weekday]
  )

  const [counter, setCounter] = React.useState(customOption?.interval)
  const [errors, setErrors] = React.useState<Record<string, unknown>>({})

  const handleChangeCustomRepeat = (repeat: CustomRepeat) => {
    setCustomRepeat(repeat)
    setCounter(1)
  }

  // const handleChangeMonthOptions = ({
  //   title,
  //   value,
  // }: {
  //   title: string
  //   value: string
  // }) => {
  //   setMonthOptions({ title, value })
  // }

  function handleWeekdaysCheck(day: string): void {
    if (onWeekdays[day]) {
      setOnWeekdays(() => {
        delete onWeekdays[day]
        return { ...onWeekdays }
      })
    } else {
      setOnWeekdays((prev) => {
        return {
          ...prev,
          [day]: day,
        }
      })
    }
  }

  function handleCounter(e: React.FocusEvent<HTMLInputElement>) {
    const counterValue = parseInt(e.target.value)
    setErrors({ counter: null })
    if (!counterValue) {
      setErrors({ counter: "Field is Required" })
    }
    if (counterValue < 1) {
      setErrors({
        counter: "It should be a positive number",
      })
    }
    setCounter(
      counterValue < 1 || counterValue > 999 || isNaN(counterValue)
        ? null
        : counterValue
    )
  }

  const handleSaveCustomOptions = () => {
    const emptyErrors = isEmpty(values(errors).filter((e) => e))
    if (!emptyErrors) {
      return
    }

    // const daysArray = Object.keys(weekDays)
    // const rruleDay = daysArray[
    //   dayIndex === -1 ? daysArray?.length - 1 : dayIndex
    // ] as Weekdays

    // const nthObject = RRule[rruleDay].nth(
    //   monthOptions.value === "firstDay" ? 1 : -1
    // ) as SingleWeekDay

    const options = {
      freq: customRepeat as CustomRepeat,
      interval: counter || 1,
      ...(customRepeat === "weekly" && {
        byweekday: Object.keys(onWeekdays) as Weekdays[],
      }),
      ...(customRepeat === "monthly" && {
        byweekday: [
          RRule[dayOfWeek as Weekdays].nth(parseInt(weekDayFrequency)),
        ] as SingleWeekDay[],
      }),
      ...(customRepeat === "yearly" && {
        byweekday: [
          RRule[dayOfWeek as Weekdays].nth(parseInt(weekDayFrequency)),
        ] as SingleWeekDay[],
        bymonth: [startMonthIndex + 1],
      }),

      // TODO: Keeping this for a while until change is finally accepted

      // ...(customRepeat === "monthly" && {
      //   byweekday: [
      //     RRule[dayOfWeek as Weekdays].nth(parseInt(weekDayFrequency)),
      //   ] as SingleWeekDay[],
      // }),
      // ...(customRepeat === "yearly" && {
      //   byweekday: [
      //     RRule[dayOfWeek as Weekdays].nth(parseInt(weekDayFrequency)),
      //   ] as SingleWeekDay[],
      //   bymonth: [startMonthIndex + 1],
      // }),
    }

    setCustomOptions({ slug: itemSlug, customOptions: options })
    setShowCustomModal(false)
  }

  return (
    <Modal
      config={{
        width: "3xl",
        showCloseButton: false,
        showCloseIcon: false,
      }}
      onClose={() => setShowCustomModal(false)}
    >
      <div className="p-3 md:p-0">
        <h4 className="font-bold text-black text-15px">Add custom schedule</h4>
        <div className="flex flex-wrap items-center gap-6 my-6 sm:flex-nowrap">
          <span className="text-sm font-normal text-black w-44">
            Items repeat every
          </span>
          <div className="relative w-full md:w-auto sm:flex-1">
            <input
              className="h-12 input input--md "
              defaultValue={1}
              max={999}
              min={1}
              onChange={handleCounter}
              type="number"
              value={counter}
            />
            {errors?.["counter"] && (
              <span className="absolute left-0 text-red-600 -bottom-6">
                {errors?.counter}
              </span>
            )}
          </div>

          <DropDown
            handleSelect={handleChangeCustomRepeat}
            value={Object.keys(customOptions).find(
              (key) => customOptions[key] === customRepeat
            )}
            valueObj={customOptions}
          />
        </div>

        {customRepeat === "weekly" && (
          <>
            <div className="flex items-center gap-6 mt-6">
              <span className="text-sm font-normal text-black w-44">
                Repeat on
              </span>

              {customRepeat === "weekly" && (
                <div className="flex items-center gap-6 pb-4">
                  {Object.keys(weekDays).map((day) => (
                    <div
                      className="flex flex-col justify-center text-center"
                      key={day}
                    >
                      <input
                        checked={onWeekdays[day]}
                        className="mb-2"
                        id={day}
                        name={day}
                        onClick={() => handleWeekdaysCheck(day)}
                        type="checkbox"
                      />{" "}
                      <label htmlFor={day}>{weekDays[day]}</label>
                    </div>
                  ))}
                </div>
              )}

              {/* {customRepeat === "monthly" && (
                <div className="w-72">
                  <DropDown
                    handleSelect={handleChangeMonthOptions}
                    value={monthRepeatOptions[monthOptions.value]?.title}
                    valueObj={monthRepeatOptions}
                  />
                </div>
              )} */}
            </div>
          </>
        )}

        {(customRepeat === "yearly" || customRepeat === "monthly") && (
          <>
            <div className="flex items-center gap-6 mt-6">
              <span className="text-sm font-normal text-black w-44">
                Select days of week{" "}
                {customRepeat === "yearly"
                  ? `in ${monthsFull[startMonthIndex]}`
                  : ""}
              </span>

              <DropDown
                handleSelect={(optSelected) =>
                  setWeekDayFrequency(optSelected.value)
                }
                value={daysOfWeekFrequency[weekDayFrequency.toString()].title}
                valueObj={daysOfWeekFrequency}
              />

              <DropDown
                handleSelect={(optSelected) => setDayOfWeek(optSelected.value)}
                value={weekDays[dayOfWeek]}
                valueObj={weekDaysDropdownOptions}
              />
            </div>
          </>
        )}

        <div className="flex flex-wrap items-center justify-between gap-3 mt-14 ">
          <span
            className="font-normal text-red-600 cursor-pointer text-13px"
            onClick={() => {
              deleteCustomOption(itemSlug)
              setPattern({
                title: "Doesn't repeat",
                value: "single-occurrence",
              })
              setShowCustomModal(false)
            }}
          >
            Delete custom schedule
          </span>

          <div className="flex items-center justify-end w-full gap-4">
            <Button
              onClick={() => {
                setShowCustomModal(false)
              }}
              variant="secondary"
            >
              cancel
            </Button>
            <Button onClick={handleSaveCustomOptions}>Save Schedule</Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CustomOptionsModal

type DropDownProps = {
  valueObj: GenericObject
  value: string
  handleSelect: (repeat: any) => void
}

function DropDown({
  valueObj = {},
  value,
  handleSelect,
}: DropDownProps): JSX.Element {
  const dropDownRef = React.useRef<HTMLDivElement>()
  const [expandOptions, setExpandOptions] = React.useState<boolean>(false)

  useClickAway(dropDownRef, () => {
    if (expandOptions) {
      setExpandOptions(false)
    }
  })

  return (
    <div className="relative flex-1" ref={dropDownRef}>
      <div
        className="flex items-center h-12 cursor-pointer select-none input input--md"
        onClick={() => setExpandOptions((prev) => !prev)}
      >
        <span className="flex-1 font-normal text-black text-15px">{value}</span>

        <Icon className=" text-primaryBlue" name="ChevronDown" />
      </div>

      {expandOptions && (
        <div className="absolute z-10 w-full px-4 pt-1 bg-white border  border-gray4 rounded-b-md">
          {Object.keys(valueObj).map((item) => (
            <div
              className={`text-13px ${
                value === (valueObj[item]?.title || item)
                  ? "font-bold text-primaryBlue"
                  : "font-normal text-black"
              }  pb-1 cursor-pointer`}
              key={item}
              onClick={() => {
                setExpandOptions(false)
                handleSelect(valueObj[item])
              }}
            >
              {valueObj[item]?.title || item}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
