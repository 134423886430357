import React, { useCallback } from "react"
import { AUTH_MODAL_ACTIONS, MODAL_TYPES } from "~src/common/constants"
import { checklistTitle, getChecklistOwner } from "~src/common/lib"
import {
  ChecklistDescriptionDisplay,
  ChecklistMetadataProps,
  ChecklistVerifiedTick,
  Icon,
  Link,
  Tooltip,
} from "~src/components"
import { ChecklistMetadata } from "~src/components/ChecklistMetadata"
import { useShowAuthModals } from "~src/hooks/useShowAuthModals"
import { routes } from "~src/routes"
import { useStore } from "~src/store"

export interface StarredChecklistCardProps {
  checklist: StarredChecklistRoot
  config?: ChecklistMetadataProps["config"]
  onStarRemoved?: () => void
  highlight?: boolean
}

export function StarredChecklistCard({
  checklist,
  config = { showVisibility: false, showOwner: true },
  onStarRemoved,
}: StarredChecklistCardProps): JSX.Element {
  const {
    actions: {
      system: { showToastAlert },
      checklist: { unStarChecklist, starChecklist },
    },
    state: {
      auth: { user },
    },
  } = useStore()

  const { handleModal } = useShowAuthModals()

  const [isStarred, setIsStarred] = React.useState<boolean>(false)

  const {
    id,
    objectID,
    title,
    description,
    slug,
    trashedAt: isTrashedChecklist = null,
  } = checklist.starredChecklist

  React.useEffect(() => {
    if (user?.starredChecklists?.length > 0 && (objectID || id)) {
      const isStarByUser = user.starredChecklists?.some(
        (userStarredChecklist: StarredChecklistType) =>
          userStarredChecklist.starredChecklist === (objectID || id)
      )
      setIsStarred(isStarByUser)
    } else {
      setIsStarred(false)
    }
  }, [user, id, objectID])

  if (!checklist) return <></>

  const chklOwner = getChecklistOwner(checklist.starredChecklist)

  const handleChecklistFavourite = useCallback(async () => {
    if (!user) {
      handleModal(MODAL_TYPES.login, {
        key: "star",
        description: AUTH_MODAL_ACTIONS.star,
      })
      return
    }

    if (isStarred) {
      const result = await unStarChecklist(checklist.starredChecklist)
      setIsStarred(false)
      if (result) onStarRemoved?.()
      if (!result) {
        showToastAlert({
          text:
            "There was error in unstaring the checklist, please try again sometime later.",
          autoHide: true,
          type: "danger",
          iconVariant: "solid",
        })
      }

      return
    }

    const result = await starChecklist(checklist.starredChecklist)
    setIsStarred(true)
    if (!result) {
      showToastAlert({
        text:
          "There was error in starring the checklist, please try again sometime later.",
        autoHide: true,
        type: "danger",
        iconVariant: "solid",
      })
    }
  }, [
    isStarred,
    user,
    handleModal,
    unStarChecklist,
    starChecklist,
    onStarRemoved,
    showToastAlert,
  ])

  return (
    <article className="px-6 py-6 mb-6 ml-2 bg-white border rounded-md">
      <div>
        <div className={"flex justify-between flex-row"}>
          <header className={`flex flex-row truncate`}>
            <h3
              className={`text-xl leading-tight truncate font-bold ${
                isTrashedChecklist ? "opacity-50 pointer-events-none" : ""
              }`}
            >
              <div className="flex items-center">
                <Link
                  href={routes.checklists.show(slug, chklOwner)}
                  tooltip={checklistTitle(title)}
                >
                  {checklistTitle(title)}
                </Link>
                {checklist.starredChecklist?.verified && (
                  <ChecklistVerifiedTick />
                )}
              </div>
            </h3>
            {isTrashedChecklist && (
              <span
                className="inline-flex items-center justify-center h-4 px-2 py-2 m-1 text-xs leading-none uppercase rounded-full text-black-100"
                style={{ background: "#FEF2F2" }}
                title="This checklist has been deleted by the creator"
              >
                Deleted
              </span>
            )}
          </header>

          <Tooltip
            tooltipTitle={
              isStarred
                ? "Remove this checklist from Your Stars"
                : "Add this checklist to Your Stars"
            }
          >
            <button
              onClick={handleChecklistFavourite}
              className="px-3 ml-5 blue-background-important btn--secondary btn "
            >
              <Icon
                fixedWidth
                name="Star"
                variant={isStarred ? "solid" : "light"}
              />
            </button>
          </Tooltip>
        </div>
        {description && (
          <div
            className={`text-sm pt-1 text-gray-700 w-full ${
              isTrashedChecklist ? "opacity-50" : ""
            }`}
          >
            <ChecklistDescriptionDisplay description={description} />
          </div>
        )}
      </div>

      <div
        className={`mt-4 ${
          isTrashedChecklist ? "opacity-50 pointer-events-none" : ""
        }`}
      >
        <ChecklistMetadata
          checklist={checklist.starredChecklist}
          config={config}
          starredChecklist={checklist}
        />
      </div>
    </article>
  )
}
