import React, { useEffect, useState } from "react"
import { ChecklistItemsHelper } from "@bonsaichecklist/bonsai-utils"
import {
  Alert,
  AttachmentTiles,
  ChecklistItemBody,
  ExpansionCaret,
  IconButton,
  Link,
} from "~src/components"
import { routes } from "~src/routes"
import { RunService } from "~src/services"
import classNames from "classnames"
import { sortChecklistItems, sortRunItems, storage } from "~src/common/lib"

export interface ChecklistRunProps {
  run?: ChecklistRun
  checklist?: Checklist
  handleChange?: (run: ChecklistRun) => void
  handlePublicRun?: (item: ChecklistRunItem, checked: boolean) => void
}

export function ChecklistRun({
  run,
  checklist,
  handleChange,
  handlePublicRun,
}: ChecklistRunProps): JSX.Element {
  const [runItems, setRunItems] = useState<ChecklistRunItem[]>(run?.items)
  useEffect(() => {
    setRunItems(run?.items)
  }, [run, checklist, run?.items])

  useEffect(() => {
    if (runItems.length) setItemMap(ChecklistItemsHelper.makeItemMap(runItems))
  }, [runItems])

  const [itemMap, setItemMap] = React.useState<ChecklistRunItemMap>(
    ChecklistItemsHelper.makeItemMap(run?.items)
  )

  const sortedChecklistItems = sortChecklistItems(itemMap)

  const items = sortRunItems(
    run?.itemsToRun,
    sortedChecklistItems,
    itemMap,
    false
  )

  async function handleCheck(
    item: ChecklistRunItem,
    checked: boolean
  ): Promise<void> {
    const toUpdate = [
      item,
      ...ChecklistItemsHelper.getChildItems(itemMap, item.slug),
    ]

    const updatedMap = { ...itemMap }
    for (const key in itemMap) {
      if (Object.prototype.hasOwnProperty.call(itemMap, key)) {
        const i = itemMap[key]
        if (toUpdate.includes(i)) {
          i.checked = checked
        }
      }
    }
    setItemMap(updatedMap)

    if (checked) {
      const chklRun = await RunService.checkItem(
        checklist.slug,
        run.slug,
        item.slug
      )

      if (typeof handleChange === "function") {
        handleChange(chklRun)
      }
    } else {
      const chklRun = await RunService.unCheckItem(
        checklist.slug,
        run.slug,
        item.slug
      )
      if (typeof handleChange === "function") {
        handleChange(chklRun)
      }
    }
  }

  if (!run) return <></>

  if (!items?.length) {
    return (
      <Alert
        className="my-8"
        keepOpen
        text="This checklist has no items..."
        type="info"
      />
    )
  }

  return (
    <div>
      {items?.map((item) => {
        return (
          <Item
            handlePublicRun={handlePublicRun}
            item={item}
            itemMap={itemMap}
            itemsToRun={run.itemsToRun}
            key={item.slug}
            local={run?.local}
            onCheck={handleCheck}
          />
        )
      })}
    </div>
  )
}

function Item({
  itemMap,
  item,
  itemsToRun,
  onCheck,
  local,
  handlePublicRun,
}: {
  itemMap: ChecklistRunItemMap
  item: ChecklistRunItem
  itemsToRun: string[]
  local: boolean
  onCheck: (item: ChecklistRunItem, checked: boolean) => Promise<void>
  handlePublicRun: (item: ChecklistRunItem, checked: boolean) => void
}): JSX.Element {
  const [showAttachments, setShowAttachments] = React.useState<boolean>(false)
  const [expanded, setExpanded] = React.useState<boolean>(false)
  const subItems = ChecklistItemsHelper.getSubItemsOfParent(itemMap, item.slug)
  const subItemMap = ChecklistItemsHelper.makeItemMap(subItems)
  const hasItems = itemsToRun?.length
    ? itemsToRun?.some((slug) => subItemMap[slug])
    : subItems.length > 0

  const localRun = JSON.parse(storage.get("initialRunData") || null)

  function handleToggleExpand(): void {
    setExpanded((exp) => !exp)
  }

  async function handleChange(
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    // If the item has children and is collapsed, first expand
    // the sub items before allowing checking off the item.
    // See [ch482] for details.

    if (hasItems && !expanded) {
      e.preventDefault()
      setExpanded(true)
      e.target.checked = false
      return
    }

    if (local) {
      handlePublicRun(item, e.target.checked)
      return
    }

    await onCheck(item, e.target.checked)
  }

  const hasAttachments = Boolean(item.attachments?.length)

  const itemClasses =
    "flex flex-row items-center px-3 py-1 rounded hover:bg-gray-100"

  const isEnabled =
    !itemsToRun?.length ||
    itemsToRun.includes(item.slug) ||
    itemsToRun.includes(
      ChecklistItemsHelper.getTopMostParentOfItem(itemMap, itemMap[item.parent])
        ?.slug
    ) ||
    true

  if (itemsToRun?.length && !itemsToRun.includes(item.slug)) return <></>

  return (
    <div>
      <div
        className={classNames(itemClasses, {
          "line-through": !isEnabled,
          "text-gray-500": !isEnabled,
        })}
        key={item.id}
      >
        <div className="flex-shrink-0 w-6 mr-2">
          {hasItems && (
            <Link onClick={handleToggleExpand} unstyled>
              <ExpansionCaret expanded={expanded} />
            </Link>
          )}
        </div>
        <div className={isEnabled ? "mr-3" : "mr-7"}>
          {isEnabled && (
            <input
              checked={
                (local && localRun.checkedItems[item.slug]) || item.checked
              }
              disabled={!isEnabled}
              onChange={handleChange}
              type="checkbox"
            />
          )}
        </div>
        {item.linkedFrom ? (
          <Link href={routes.checklists.show(item.linkedFromSlug)}>
            <ChecklistItemBody item={item} />
          </Link>
        ) : (
          <ChecklistItemBody item={item} />
        )}
        {hasAttachments && (
          <div className="ml-auto">
            <IconButton
              icon="Paperclip"
              onClick={(): void => setShowAttachments(!showAttachments)}
              size="sm"
              variant="link"
            />
          </div>
        )}
      </div>
      {hasAttachments && showAttachments && (
        <div className="flex flex-row items-center">
          <div className="w-12 mr-2" />
          <AttachmentTiles
            attachments={item.attachments}
            itemSlug={item.slug}
            size="10rem"
            viewOnly
          />
        </div>
      )}
      {expanded && hasItems && (
        <div className="pl-4">
          {subItems.map((i) => (
            <Item
              handlePublicRun={handlePublicRun}
              item={i}
              itemMap={itemMap}
              itemsToRun={itemsToRun}
              key={i.slug}
              local={local}
              onCheck={onCheck}
            />
          ))}
        </div>
      )}
    </div>
  )
}
