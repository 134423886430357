import React from "react"
import { Alert, AttachmentTiles, Button, Icon, Modal } from "~src/components"
import { useActions } from "~src/store"

export interface AttachmentModalProps {
  item: ChecklistItem
  onClose: () => void
}

export function AttachmentModal({
  item,
  onClose,
}: AttachmentModalProps): JSX.Element {
  const fileField = React.useRef<HTMLInputElement>(null)
  const {
    checklist: { uploadAttachments },
  } = useActions()

  const [error, setError] = React.useState<string>("")
  const [toUpload, setToUpload] = React.useState<File[]>([])
  const [uploading, setUploading] = React.useState<boolean>(false)

  function handleShowFileDialog(): void {
    // Clear out file field in case user selects the
    // same item again.
    fileField.current.value = ""
    fileField.current.click()
  }

  async function handleFileSelected(
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    const files = Array.from(e.target.files)

    for (let i in files) {
      const size = files[i].size / 1000000
      const extension = files[i].name.split(".").pop().toLowerCase()
      if (extension === "mov" && size > 10) {
        setError("File limit is <10Mb")
        setUploading(false)
        setToUpload([])
        return
      } else if (extension === "pdf" && size > 25) {
        setError("File limit is <25Mb")
        setUploading(false)
        setToUpload([])
        return
      }
    }

    setError("")
    setUploading(true)
    setToUpload(files)

    try {
      await uploadAttachments({ itemSlug: item.slug, files })
    } catch (error) {
      setError(String(error))
    }

    setUploading(false)
    setToUpload([])
  }

  const hasAttachments = item.attachments?.length

  return (
    <Modal
      config={{ width: "6xl" }}
      onClose={onClose}
      title="Upload Attachments"
    >
      <div className="mb-10">
        <input
          accept=".pdf, image/png, image/jpeg, image/gif, video/quicktime, image/x-png, image/vnd.mozilla.apng, .mov, .qt"
          hidden
          multiple
          onChange={handleFileSelected}
          ref={fileField}
          type="file"
        />
        <div className="flex flex-row items-center my-8">
          <Button
            disabled={uploading}
            leftIcon="Paperclip"
            onClick={handleShowFileDialog}
          >
            Choose Files
          </Button>
        </div>
        {error && (
          <Alert className="my-6" text={error} onHide={() => setError(null)} />
        )}
        {Boolean(toUpload?.length) && (
          <div className="my-6 text-sm text-gray-600">
            <div className="my-2">
              <svg
                className="w-5 h-5 mr-3 -ml-1 text-white animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              Uploading <strong>{toUpload.length}</strong> file
              {toUpload.length > 1 && "s"}...
            </div>
          </div>
        )}
        {hasAttachments ? (
          <div className="my-10">
            <AttachmentTiles
              attachments={item.attachments}
              itemSlug={item.slug}
            />
          </div>
        ) : (
          <>
            {!uploading && (
              <p className="my-8 text-sm text-gray-400">
                This checklist item does not have any attachments yet, please
                add one with the button above.
              </p>
            )}
          </>
        )}
      </div>
    </Modal>
  )
}
