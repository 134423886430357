import React, { useMemo } from "react"
import { createPopper } from "@popperjs/core"
import {
  Icon,
  IconButton,
  ItemInfoPopover,
  getCopyChecklistRouterHelper,
} from "~src/components"
import { DateHelper } from "@bonsaichecklist/bonsai-utils"
import { Link } from "~src/components"
import { routes } from "~src/routes"
import { useState } from "~src/store"

interface DropDownPropTypes {
  setShowItemPopover: any
  showItemPopover: boolean
  item: ChecklistItem
  dropDownRef: any
  ellipsisRef: any
  showChecklistActions: boolean
  onShare?: () => void
  runSlug?: string
  isUpdated?: boolean
}

export function ItemActions({
  setShowItemPopover,
  showItemPopover,
  item,
  dropDownRef,
  ellipsisRef,
  showChecklistActions,
  onShare,
  runSlug,
  isUpdated,
}: DropDownPropTypes): JSX.Element {
  const {
    checklist: { copyChecklistHashMap },
  } = useState()
  const [showDropdown, setShowDropdown] = React.useState<boolean>(
    showChecklistActions
  )
  React.useEffect(() => {
    if (showChecklistActions) {
      createPopper(ellipsisRef.current, dropDownRef.current, {
        placement: "left-end",
      })
      setShowDropdown(true)
    } else {
      setShowDropdown(false)
    }
  }, [showChecklistActions])

  const {
    slug: copyChklSlug,
    username: copyChklUsername,
    title: copyChklTitle,
  } = useMemo(() => {
    return getCopyChecklistRouterHelper(copyChecklistHashMap, item)
  }, [copyChecklistHashMap, item])

  if (!showChecklistActions) return null

  return (
    <>
      <div className={showDropdown ? "flex flex-wrap bg-blue-500" : "hidden"}>
        <div ref={dropDownRef}>
          <div className="w-full">
            <ItemInfoPopover
              item={item}
              parentRef={dropDownRef}
              showInfoPoppper={showItemPopover}
            />
            <div
              className={
                (showDropdown ? "flex flex-col " : "hidden ") +
                " rounded-md gap-2 w-21 py-1 z-40 bg-none"
              }
              style={{ minWidth: "12rem", zIndex: 999 }}
            >
              {runSlug && (
                <div className="w-full px-2 py-2 text-sm font-normal bg-transparent bg-white border shadow-xl border-grey-400 whitespace-nowrap text-blueGray-700">
                  <button
                    className="block py-2 px-4 w-full text-start  focus:outline-none hover:!bg-gray-100 dark:hover:bg-gray-600 !text-blue-600 dark:hover:text-white"
                    onClick={onShare}
                  >
                    <Icon className="mr-3 text-gray-600" name="QrCode" />
                    Share link to this item
                  </button>
                </div>
              )}
              <div className="flex flex-col">
                <div
                  className={
                    (item?.copiedFrom ? "flex flex-row " : "hidden ") +
                    "bg-white shadow-xl  border border-grey-400  items-center  text-sm py-2 px-2 font-normal w-full whitespace-nowrap bg-transparent text-blueGray-700"
                  }
                >
                  <span className="items-start -mt-5">
                    <Icon
                      className="ml-1 mr-2 text-blue-500"
                      name="CircleHelp"
                      size="sm"
                    />
                  </span>
                  <span className="items-center">
                    This item was copied from <br />
                    <Link
                      href={routes.checklists.show(
                        copyChklSlug,
                        copyChklUsername
                      )}
                    >
                      {copyChklTitle}{" "}
                    </Link>
                  </span>
                </div>

                {isUpdated && (
                  <div
                    className={
                      "flex bg-white shadow-xl border border-grey-400 items-center text-sm py-2 px-2 font-normal w-full whitespace-nowrap bg-transparent text-blueGray-700"
                    }
                  >
                    <span className="items-start -mt-5">
                      <Icon
                        className="ml-1 mr-2 text-blue-500"
                        name="CircleHelp"
                        size="sm"
                      />
                    </span>
                    <div className="items-center">
                      Copied Source Updated:
                      <br />
                      <Link
                        href={routes.checklists.show(
                          copyChklSlug,
                          copyChklUsername
                        )}
                      >
                        {copyChklTitle}{" "}
                      </Link>{" "}
                    </div>
                  </div>
                )}
              </div>

              <a
                className={
                  "hidden flex-row items-center text-sm py-2 px-2 font-normal w-full whitespace-nowrap bg-transparent text-blueGray-700"
                }
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <IconButton
                  className="items-center"
                  icon="Trash"
                  size="sm"
                  variant="link"
                />
                Delete
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
