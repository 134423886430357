import React from "react"
import { useClickAway } from "react-use"
import dayjs from "dayjs"
import InfiniteScroll from "react-infinite-scroll-component"
import classNames from "classnames"
import { useRouter } from "next/router"
import { IconButton, Activity, ActivityDate, Tooltip } from "~src/components"
import { UserService } from "~src/services"
import { useState, useActions } from "~src/store"

export function NotificationsDropdown(): JSX.Element {
  const [expanded, setExpanded] = React.useState<boolean>(false)
  const ref = React.useRef<HTMLDivElement>(null)
  const [activities, setActivities] = React.useState<ActivityLog[]>([])
  const [page, setPage] = React.useState(0)
  const [hasNewActivities, setHasNewActivities] = React.useState(false)
  const [lastActivitySeenAt, setLastActivitySeenAt] = React.useState<Date>(null)
  const router = useRouter()

  const {
    auth: { updateUserLastActivitySeenAt },
  } = useActions()

  const {
    auth: { user, state },
  } = useState()

  async function getActivities() {
    const currentPage = page + 1
    const query = {
      page: currentPage,
      limit: 10,
    }
    const {
      activities: newActivities = [],
      updatedLastActivitySeenAt,
      hasNew,
    } = await UserService.activities(query.page, query.limit)

    setHasNewActivities(hasNew)
    setActivities([...activities, ...newActivities])
    setPage(currentPage)
    if (updatedLastActivitySeenAt)
      setLastActivitySeenAt(updatedLastActivitySeenAt)
  }

  React.useEffect(() => {
    if (state === "authenticated") {
      getActivities()
    }
  }, [router])

  function handleExpand() {
    setExpanded(!expanded)
  }

  useClickAway(ref, (e) => {
    if (!expanded) return
    setExpanded(false)
    setHasNewActivities(false)
    updateUserLastActivitySeenAt(lastActivitySeenAt)
  })

  return (
    <div className="relative flex flex-row items-center" ref={ref}>
      <Tooltip tooltipTitle={"Notifications"}>
        <div>
          <IconButton
            icon="Bell"
            iconStyles={"h-4 md:h-5"}
            onClick={handleExpand}
            tooltip=""
            variant="link"
          />
          {Boolean(activities.length) && hasNewActivities && (
            <span className="absolute inline-block w-2 h-2 bg-red-600 rounded-full top-1 right-3"></span>
          )}
        </div>
      </Tooltip>
      {expanded && (
        <InfiniteScroll
          dataLength={activities.length}
          hasMore
          loader={() => ""}
          next={getActivities}
          scrollThreshold={1}
          style={{ overflow: "hidden" }}
        >
          <div
            className="absolute z-50 overflow-y-auto bg-white border border-gray-300 rounded-md shadow-lg max-h-72 w-72 sm:w-80 md:w-96"
            style={{
              top: 40,
              right: -80,
            }}
          >
            {activities.length ? (
              activities.map((activity, index) => (
                <div
                  className={classNames({
                    "bg-blue-100": dayjs(activity.createdAt).isAfter(
                      user.lastActivitySeenAt
                    ),
                  })}
                  key={activity.id}
                >
                  <Activity
                    activity={activity}
                    cb={() => setExpanded(false)}
                    className="px-2 pt-2"
                    showChecklistTitle
                  />
                  <ActivityDate
                    activity={activity}
                    className="px-2 text-right"
                  />
                  {activities.length - 1 !== index && (
                    <hr className="mt-2 border-gray-300" />
                  )}
                </div>
              ))
            ) : (
              <p className="px-4">No New Activity Found</p>
            )}
          </div>
        </InfiniteScroll>
      )}
    </div>
  )
}
