import debug from "debug"
import "draft-js/dist/Draft.css"
import {
  DraftHandleValue,
  Editor as DraftEditor,
  EditorState,
  DraftEditorCommand,
  getDefaultKeyBinding,
  convertFromHTML,
  ContentState,
} from "draft-js"
import React from "react"
import { Toolbar, ToolbarProps } from "~src/components/Editor/Toolbar"
import { EditorHelper } from "~src/common/lib/EditorHelper"
import { inputClassNames } from "~src/common/lib/inputClassNames"
import { Icon, IconName, Tooltip } from "~src/components"
import classnames from "classnames"
import { useClickAway } from "react-use"

const d = debug("ChainList/components/Editor")

export type EditorCommand =
  | DraftEditorCommand
  | "save"
  | "indent"
  | "unindent"
  | "move-up"
  | "move-down"
  | "insert-above"
  | "insert-below"
  | "focus-prev"
  | "focus-next"
  | "focus-name"
  | "focus-first-item"
  | "add-item"
  | "show-link"

export interface ActionField {
  badgeContent?: number
  title: string
  icon: IconName
  alwaysShow?: boolean
  className?: string
  action: (...args: any) => any
}

export interface EditorProps {
  actions?: ActionField[]
  className?: string
  cursorAtEnd?: boolean
  defaultValue: string
  disabled?: boolean
  editorKey: string
  focused?: boolean
  keyBindingFn?: (editorState: EditorState) => (e: any) => EditorCommand | void
  maxLimit?: number
  onKeyCommand?: (
    command: EditorCommand,
    editorState: EditorState
  ) => DraftHandleValue
  onBlur?: () => void
  onFocus?: () => void
  placeholder?: string
  setContent: (html: string) => void
  size?: "sm" | "lg" | "md"
  style?: React.CSSProperties
  toolbarProps?: {
    toolbarItemSlug?: string
    variant: ToolbarProps["variant"]
  }
}

export function Editor({
  actions,
  className,
  cursorAtEnd = false,
  defaultValue,
  disabled,
  editorKey,
  focused,
  keyBindingFn,
  maxLimit,
  onBlur,
  onFocus,
  onKeyCommand,
  placeholder,
  setContent,
  size = "sm",
  style,
  toolbarProps,
}: EditorProps): JSX.Element {
  const [editorState, setEditorState] = React.useState<EditorState>(
    defaultValue
      ? EditorHelper.editorFromHTML(defaultValue || "", cursorAtEnd)
      : EditorHelper.createEmptyState()
  )

  const [currentLinkURL, setCurrentLinkURL] = React.useState<string>("")
  const [shouldShowToolbar, setShouldShowToolbar] = React.useState<boolean>(
    false
  )
  const [shouldShowLinkEditor, setShouldShowLinkEditor] = React.useState<
    boolean
  >(false)

  // show or hide action items
  const [showActions, setShowActions] = React.useState<boolean>(false)

  const editorRef = React.useRef<HTMLDivElement>(null)

  useClickAway(editorRef, (e: GenericObject) => {
    EditorHelper.createEmptyState()
    setShouldShowToolbar(false)
    setShouldShowLinkEditor(false)
    handleBlur()
  })

  // If the user focuses on another field, we should clear the active selection
  // so the editor does not flash when re-selecting this field.
  React.useEffect(() => {
    if (!focused && EditorHelper.hasSelectedText(editorState)) {
      handleSetContent(EditorHelper.clearSelection(editorState))
    }
  }, [focused, editorState])

  React.useEffect(() => {
    // Show actions items on focused otherwise keep them hidden.
    setShowActions(focused)

    if (focused) {
      handleFocusEditor()
      if (cursorAtEnd) setEditorState(EditorHelper.moveCursorToEnd(editorState))
    }
  }, [focused])

  const ref = React.createRef<DraftEditor>()

  function handleSetContent(e: EditorState): void {
    // Update the local editor state
    setEditorState(e)

    // prevent from resetting when link editor is opened.
    if (!shouldShowLinkEditor) {
      const selected = EditorHelper.hasSelectedText(e)
      // const overLink = EditorHelper.cursorOverLink(e)
      setShouldShowToolbar(selected)
    }

    // Store the HTML in our store for when the checklist is saved.
    setContent(EditorHelper.htmlFromEditor(e))
  }

  function handleChange(e: EditorState): void {
    handleSetContent(e)
  }

  function handleFocusEditor(): void {
    ref.current?.focus()
  }

  function handleBold(): void {
    handleSetContent(EditorHelper.bold(editorState))
  }

  function handleItalic(): void {
    handleSetContent(EditorHelper.italic(editorState))
  }

  function handleCode(): void {
    handleSetContent(EditorHelper.code(editorState))
  }

  function handleStrikethrough(): void {
    handleSetContent(EditorHelper.strikethrough(editorState))
  }

  function handleLink(url: string): void {
    handleSetContent(EditorHelper.link(editorState, url))
    setShouldShowLinkEditor(false)
  }

  function handleUnlink(): void {
    handleSetContent(EditorHelper.unlink(editorState))
    setShouldShowLinkEditor(false)
  }

  function handleClearFormatting(): void {
    handleSetContent(EditorHelper.clearFormatting(editorState))
  }

  function handleShowToolbar(): void {
    setShouldShowToolbar(true)
    handleHideLinkEditor()
  }

  function handleHideToolbar(): void {
    setShouldShowToolbar(false)
    handleSetContent(EditorHelper.clearSelection(editorState))
  }

  function handleShowLinkEditor(): void {
    setCurrentLinkURL(EditorHelper.currentLinkURL(editorState))
    setShouldShowLinkEditor(true)
    setShouldShowToolbar(false)
  }

  function handleHideLinkEditor(): void {
    setShouldShowLinkEditor(false)
    setCurrentLinkURL("")
  }

  function handleBeforeInput(
    chars: string,
    e: EditorState,
    eventTimeStamp: number
  ): DraftHandleValue {
    if (
      maxLimit &&
      EditorHelper.getCurrentContentTextLength(editorState) >= maxLimit
    ) {
      return "handled"
    }
    return "not-handled"
  }

  function handlePastedText(
    text: string,
    html: string,
    editorState: EditorState
  ): DraftHandleValue {
    if (
      maxLimit &&
      `${EditorHelper.getCurrentContentText(editorState)}${text}`.length >
        maxLimit
    ) {
      const pastedDescription = `${EditorHelper.getCurrentContentText(
        editorState
      )}${text}`

      setContent(pastedDescription)
      const blocksFromHTML = convertFromHTML(pastedDescription)

      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      )

      // Set the new editorState
      setEditorState(EditorState.createWithContent(state))

      return "handled"
    }

    return "not-handled"
  }

  function handleKeyCommand(
    command: EditorCommand,
    editorState: EditorState
  ): DraftHandleValue {
    let isHandled
    if (onKeyCommand) {
      const handleVal = onKeyCommand(command, editorState)
      isHandled = handleVal === "handled"
      if (isHandled) return "handled"
    }

    if (!isHandled && command === "show-link") {
      handleShowLinkEditor()
      return "handled"
    }

    const newState = EditorHelper.handleKeyCommand(editorState, command)
    if (!isHandled && newState) {
      d("handled command:", command)
      handleSetContent(newState)
      return "handled"
    }

    d("not handling command:", command)

    return "not-handled"
  }

  function handleBlur(): void {
    ref.current?.blur()
    onBlur && onBlur()
  }

  /**
   * Handle our custom actions by returning a command that
   * handleKeyCommand will then use to trigger the associated actions.
   *
   * Moved this out of the component so we don't have to re-create on
   * every render.
   */
  function handleKeyBindingFn(e: any): EditorCommand {
    if (keyBindingFn) {
      const customKeyBindingHandler = keyBindingFn(editorState)
      const command = customKeyBindingHandler(e)
      if (command) return command
    }

    const key = e.key.toLowerCase()

    if (key === "k" && e.metaKey) {
      return "show-link"
    }

    return getDefaultKeyBinding(e)
  }

  function handleHideActions(): void {
    setShowActions(false)
  }

  function handleShowActions(): void {
    setShowActions(true)
  }

  const cursorOverLink = EditorHelper.cursorOverLink(editorState)
  const hasSelectedText = EditorHelper.hasSelectedText(editorState)

  const containerClasses = "relative"
  const inputClasses = inputClassNames({
    className,
    disabled,
    focused,
    size,
  })

  return (
    <div className={containerClasses} ref={editorRef}>
      <Toolbar
        bold={handleBold}
        clearFormatting={handleClearFormatting}
        code={handleCode}
        currentInlineStyle={editorState.getCurrentInlineStyle()}
        currentLinkURL={currentLinkURL}
        cursorOverLink={cursorOverLink}
        focused={focused}
        hasSelectedText={hasSelectedText}
        hideLinkEditor={handleHideLinkEditor}
        hideToolbar={handleHideToolbar}
        italic={handleItalic}
        itemSlug={toolbarProps?.toolbarItemSlug}
        link={handleLink}
        shouldShowLinkEditor={shouldShowLinkEditor}
        shouldShowToolbar={shouldShowToolbar}
        showLinkEditor={handleShowLinkEditor}
        showToolbar={handleShowToolbar}
        strikethrough={handleStrikethrough}
        unlink={handleUnlink}
        variant={toolbarProps?.variant}
      />
      <div
        className={`${inputClasses} overflow-auto`}
        onClick={handleFocusEditor}
        onMouseEnter={handleShowActions}
        onMouseLeave={handleHideActions}
        style={style}
      >
        <div className="w-full overflow-auto" id={`editor-${editorKey}`}>
          <DraftEditor
            editorKey={editorKey}
            editorState={editorState}
            handleBeforeInput={handleBeforeInput}
            handleKeyCommand={handleKeyCommand}
            handlePastedText={handlePastedText}
            keyBindingFn={handleKeyBindingFn}
            onChange={handleChange}
            spellCheck
            webDriverTestID={`draft-editor-${editorKey}`}
            {...(onFocus && { onFocus })}
            placeholder={placeholder}
            ref={ref}
          />
        </div>
        {actions?.length && (
          <div
            className="flex flex-row items-center justify-between"
            style={{ minHeight: "1.65rem" }}
          >
            {actions.map(
              (actionItem) =>
                (showActions || actionItem?.alwaysShow) && (
                  <ActionFieldItem item={actionItem} key={actionItem.icon} />
                )
            )}
          </div>
        )}
      </div>
      {maxLimit && (
        <p className={`text-gray-600 text-xs italic text-right`}>
          <span
            className={`${
              EditorHelper.getCurrentContentTextLength(editorState) > maxLimit
                ? "text-red-600"
                : ""
            } `}
          >
            {EditorHelper.getCurrentContentTextLength(editorState)}
          </span>
          /{maxLimit}
        </p>
      )}
    </div>
  )
}

interface ActionFieldItemProps {
  item: ActionField
}

function ActionFieldItem({
  item: { icon, title, action, className, badgeContent },
  ...props
}: ActionFieldItemProps): JSX.Element {
  const classes = classnames(
    "mx-2 cursor-pointer text-lg text text-gray-500 hover:text-gray-700 font-bold relative  editor-action",
    className
  )

  return (
    <Tooltip tooltipTitle={title}>
      <div className={classes} onClick={action}>
        {badgeContent > 0 && <Badge badgeContent={badgeContent} />}
        <Icon name={icon} />
      </div>
    </Tooltip>
  )
}

interface BadgeProps {
  badgeContent: number
}

function Badge({ badgeContent }: BadgeProps): JSX.Element {
  return (
    <span
      className="absolute top-0 right-0 flex items-center justify-center w-3 h-3 p-2 text-xs leading-none text-white bg-blue-400 rounded-full"
      style={{ right: "-0.3rem" }}
    >
      {badgeContent >= 10 ? "9+" : badgeContent}
    </span>
  )
}
