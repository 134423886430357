import React from "react"
import { useRouter } from "next/router"
import { useActions } from "~src/store"
import { IconButton } from "~src/components"
import { ChecklistService } from "~src/services"
import { routes } from "~src/routes"

export interface TrashChecklistButtonProps {
  checklist: Checklist
  className?: string
  handleRedirecting: React.Dispatch<React.SetStateAction<boolean>>
}

export function TrashChecklistButton({
  checklist,
  handleRedirecting,
  className,
}: TrashChecklistButtonProps): JSX.Element {
  const router = useRouter()
  const {
    system: { showToastAlert },
    checklist: { setUnsavedChanges },
  } = useActions()

  const [isSchedulesLoading, setSchedulesLoading] = React.useState<boolean>(
    false
  )

  async function handleTrash(): Promise<void> {
    setSchedulesLoading(true)

    const {
      scheduledRunsCount,
      checklistRunsCount,
    } = await ChecklistService.getRunsScheduledRuns(checklist.slug)

    setSchedulesLoading(false)

    let message = ""
    if (scheduledRunsCount)
      message += `There are ${scheduledRunsCount} Scheduled Run/s, Are you sure you want to trash this? Trashing this list will also delete ${scheduledRunsCount} scheduled runs.`

    if (!scheduledRunsCount && checklistRunsCount)
      message += `There are ${checklistRunsCount} incomplete Run/s, Are you sure you want to trash this?`

    if (!scheduledRunsCount && !checklistRunsCount)
      message += `Are you sure you want to trash this list?`

    if (!window.confirm(message)) return

    await ChecklistService.trash(checklist.slug)
    showToastAlert({
      text: "Your checklist was trashed successfully",
      autoHide: true,
      type: "warning",
      iconVariant: "solid",
    })
    setUnsavedChanges(false)
    handleRedirecting(true)
    router.push(routes.generic.yourChecklists())
  }

  return (
    <div className={className}>
      <div className="flex items-center">
        <IconButton
          className="text-sm h-max-content"
          destructive
          disabled={isSchedulesLoading}
          icon="Trash"
          iconStyles={"text-red-600 mx-2"}
          onClick={handleTrash}
          tooltip={"Trash this checklist"}
          variant={"secondary"}
        />
      </div>
    </div>
  )
}
