import "react-datepicker/dist/react-datepicker.css"

import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import { RRule } from "rrule"
import { CHECKLIST_DESCRIPTION_MAX_LENGTH } from "~src/common/constants"
import {
  ChecklistHelper,
  EditorHelper,
  getNumberWithOrdinal,
  RECURRENCE_PATTERNS,
} from "~src/common/lib"
import { Button, Editor, Icon, Link } from "~src/components"
import { checklistTemplateContext } from "~src/contextStores"
import { ScheduleService } from "~src/services/ScheduleService"
import { useState as useGlobalState } from "~src/store"

import {
  ChecklistItemsHelper,
  DateHelper,
  MarkdownHelper,
} from "@bonsaichecklist/bonsai-utils"

import { weekDays, months } from "./helpers"

import { ChecklistPreview } from "../ChecklistPreview"

type RepeatUntil = "forever" | "on-date"

export type CustomRepeat = "daily" | "weekly" | "monthly" | "yearly"

interface EditScheduleFormProps {
  checklist: Checklist
  onSuccess: (param?: Schedule) => void
}

export function EditScheduleForm({
  checklist,
  onSuccess,
}: EditScheduleFormProps): JSX.Element {
  const {
    auth: { user, state: loginState },
  } = useGlobalState()

  const [loading, setLoading] = useState<boolean>(false)
  const [startedAt, setStartedAt] = useState<Date | undefined>()
  const [until, setUntil] = useState<Date | undefined>()
  const [pattern, setPattern] = useState<RECURRENCE_PATTERNS>(
    "single-occurrence"
  )
  const [repeatUntil, setRepeatUntil] = useState<RepeatUntil>("forever")
  const [focusedDescription, setFocusedDescription] = useState<boolean>(false)
  const [runTitle, setRunTitle] = useState<string>("")
  const [runDescription, setRunDescription] = useState<string>("")
  const [isDateValid, setIsDateValid] = useState<boolean>(false)

  const [customRepeat, setCustomRepeat] = useState<CustomRepeat>("daily")
  const [counter, setCounter] = useState(null)
  const [onWeekdays, setOnWeekdays] = useState<GenericObject>({})
  const [monthlyDates, setMonthlyDates] = useState<{ [key: string]: number }>(
    {}
  )
  const [yearlyRunOnMonths, setYearlyRunOnMonths] = useState<{
    [key: string]: number
  }>({})
  const [scheduleText, setScheduleText] = useState("")
  const [checkedItems, setCheckedItems] = React.useState<GenericObject>({})

  useEffect(() => {
    if (!until) return

    const isEndDateSameAsStartDate = dayjs(until).isSame(dayjs(startedAt))
    const isEndDateBeforeStartDate = dayjs(until).isBefore(dayjs(startedAt))

    if (isEndDateSameAsStartDate || isEndDateBeforeStartDate) {
      setUntil(null)
    }
  }, [startedAt, until])

  useEffect(() => {
    setOnWeekdays({})
    setMonthlyDates({})
    setYearlyRunOnMonths({})
    setScheduleText("")
  }, [customRepeat, pattern])

  useEffect(() => {
    if (pattern !== "custom") return

    const weekdays = Object.keys(onWeekdays) as Weekdays[]
    const freqMapping = {
      yearly: 0,
      monthly: 1,
      weekly: 2,
      daily: 3,
    }

    const untilTime = dayjs(startedAt).format("THH:mm:ssZZ")
    const rule = new RRule({
      freq: freqMapping[customRepeat],
      dtstart: startedAt,
      ...(until && {
        until: new Date(dayjs(until).format(`YYYY-MM-DD${untilTime}`)),
      }),
      interval: counter || 1,
      ...(customRepeat === "weekly" && {
        byweekday: weekdays.map((day) => RRule[day]),
      }),
      ...(customRepeat === "monthly" && {
        bymonthday: Object.values(monthlyDates),
      }),
      ...(customRepeat === "yearly" && {
        bymonth: Object.values(yearlyRunOnMonths),
      }),
    })

    setScheduleText(rule.toText())
  }, [
    customRepeat,
    counter,
    onWeekdays,
    monthlyDates,
    yearlyRunOnMonths,
    until,
  ])

  useEffect(() => {
    setStartedAt(getStartedDate())
    setIsDateValid(true)
  }, [])

  const helper = new ChecklistHelper(checklist)
  const isOwner = helper.isOwner(user)
  const rootItems = checklist.items
    .filter((i) => !i.parent)
    .sort(ChecklistItemsHelper.sortChecklistItemsByOrder)

  function getStartedDate(): Date {
    return dayjs().set("hour", 9).set("minute", 0).add(1, "day").toDate()
  }

  function handleEndDate(date: Date) {
    if (
      startedAt &&
      pattern !== "single-occurrence" &&
      repeatUntil === "on-date"
    ) {
      setUntil(date)
    }
  }

  function handleItemCheck(
    item: ChecklistRunItem,
    event: any,
    hasItems: boolean,
    setExpanded: (value: boolean) => void,
    expanded: boolean,
    itemMap: ChecklistItemMap
  ) {
    if (hasItems && !expanded) {
      event.preventDefault()
      setExpanded(true)
      event.target.checked = false
      return
    }

    const toUpdate = [
      item,
      ...ChecklistItemsHelper.getChildItems(itemMap, item.slug),
    ]

    toUpdate.forEach((item) => {
      if (checkedItems[item.slug] && event.target.checked === false) {
        delete checkedItems[item.slug]
        // if (Object.keys(checkedItems)) setSelectAllCheckboxes(false)
        setCheckedItems({ ...checkedItems })
      } else if (event.target.checked === true) {
        setCheckedItems((prev) => {
          prev[item.slug] = item
          return {
            ...prev,
            ...checkedItems,
          }
        })
      }
    })
  }

  function selectAllItems() {
    const selectedItems: any = checklist.items.reduce(
      (previousValue, currentValue): any => ({
        ...previousValue,
        [currentValue.slug]: currentValue.slug,
      }),
      {}
    )
    setCheckedItems(selectedItems)
  }

  function handleDateValidation(date: Date): void {
    const dateInvalid = !date || DateHelper.isPast(date)
    setIsDateValid(!dateInvalid)
  }

  function handleChangeFrequency(
    e: React.ChangeEvent<HTMLSelectElement>
  ): void {
    setPattern(e.currentTarget.value as RECURRENCE_PATTERNS)
  }

  function handleChangeRepeatUntil(
    e: React.ChangeEvent<HTMLInputElement>
  ): void {
    setRepeatUntil(e.currentTarget.value as RepeatUntil)
  }

  async function handleSubmit(e: React.FormEvent): Promise<void> {
    e.preventDefault()
    setLoading(true)

    const itemsToSchedule = Object.keys(checkedItems)

    if (itemsToSchedule.length < 1) {
      alert("At least one item needs to be selected.")
      setLoading(false)
      return
    }

    const untilTime = dayjs(startedAt).format("THH:mm:ssZZ")
    const scheduleData = {
      startedAt: new Date(dayjs(startedAt).format("YYYY-MM-DDTHH:mm:ssZZ")),
      ...(until && {
        until: new Date(dayjs(until).format(`YYYY-MM-DD${untilTime}`)),
      }),
      recurring: pattern !== "single-occurrence",
      recurrencePattern: pattern,
      ...(pattern === "custom" && {
        customOptions: {
          freq: customRepeat as CustomRepeat,
          interval: counter || 1,
          ...(customRepeat === "weekly" && {
            byweekday: Object.keys(onWeekdays) as Weekdays[],
          }),
          ...(customRepeat === "monthly" && {
            bymonthday: Object.values(monthlyDates),
          }),
          ...(customRepeat === "yearly" && {
            bymonth: Object.values(yearlyRunOnMonths),
          }),
        },
      }),
      overrides: {
        title: runTitle,
        description: runDescription,
      },
      ...(itemsToSchedule.length !== checklist.items.length && {
        itemsToSchedule,
      }),
    }

    // const schedule = await ScheduleService.create(checklist.slug, scheduleData)

    // if (schedule) onSuccess(schedule)

    setLoading(false)
  }

  function handleDescriptionFocus(): void {
    setFocusedDescription(true)
  }

  function handleDescriptionBlur(): void {
    setFocusedDescription(false)
  }

  function handleRunTitleChange(e: React.FocusEvent<HTMLInputElement>): void {
    setRunTitle(e.target.value)
  }

  function handleDescriptionChange(html: string): void {
    setRunDescription(EditorHelper.htmlToMarkdown(html))
  }

  function handleCustomRepeat(e: React.FocusEvent<HTMLSelectElement>): void {
    setCustomRepeat(e.target.value as CustomRepeat)
    setCounter(1)
  }

  function handleCounter(e: React.FocusEvent<HTMLInputElement>) {
    const counterValue = parseInt(e.target.value)
    setCounter(
      counterValue < 1 || counterValue > 999 || isNaN(counterValue)
        ? null
        : counterValue
    )
  }

  function handleWeekdaysCheck(day: string): void {
    if (onWeekdays[day]) {
      setOnWeekdays(() => {
        delete onWeekdays[day]
        return { ...onWeekdays }
      })
    } else {
      setOnWeekdays((prev) => {
        return {
          ...prev,
          [day]: day,
        }
      })
    }
  }

  function handleDatePicker(date: number) {
    if (monthlyDates[date]) {
      setMonthlyDates(() => {
        delete monthlyDates[date]
        return { ...monthlyDates }
      })
    } else {
      setMonthlyDates((prevDates) => {
        return { ...prevDates, [date]: date }
      })
    }
  }

  function handleMonthPicker(month: string, index: number) {
    if (yearlyRunOnMonths[month]) {
      setYearlyRunOnMonths(() => {
        delete yearlyRunOnMonths[month]
        return { ...yearlyRunOnMonths }
      })
    } else {
      setYearlyRunOnMonths((prevMonths) => {
        return { ...prevMonths, [month]: index }
      })
    }
  }

  const repeatOptions: {
    [key: string]: { title: string; value?: RECURRENCE_PATTERNS }
  } = {
    noRepeat: {
      title: "Do not repeat",
      value: "single-occurrence",
    },
    daily: { title: "Daily", value: "daily" },
    weekly: {
      title: `Weekly on ${DateHelper.weekday(startedAt)}`,
      value: "weekly",
    },
    biWeekly: {
      title: `Biweekly on ${DateHelper.weekday(startedAt)}`,
      value: "bi-weekly",
    },
    monthly: {
      title: `Monthly on ${getNumberWithOrdinal(
        DateHelper.dayOfMonth(startedAt)
      )}`,
      value: "weekday-of-month",
    },
    quarterly: {
      title: `Every 3 Months on ${getNumberWithOrdinal(
        DateHelper.dayOfMonth(startedAt)
      )}`,
      value: "quarterly-weekday-of-month",
    },
    semiyearly: {
      title: `Every 6 Months on ${getNumberWithOrdinal(
        DateHelper.dayOfMonth(startedAt)
      )}`,
      value: "semiyearly-weekday-of-month",
    },
    yearly: {
      title: `Yearly on ${dayjs(startedAt).format("DD MMMM")}`,
      value: "yearly",
    },
    custom: { title: "Custom", value: "custom" },
  }

  const customOptions: GenericObject = {
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    yearly: "Yearly",
  }

  const description = new MarkdownHelper(checklist?.description || "").html

  return (
    <div>
      <div className="py-2">
        <input
          className="placeholder-gray-700 input input--md"
          defaultValue={checklist.title}
          id="run-title"
          maxLength={80}
          onChange={handleRunTitleChange}
          placeholder="Checklist Title"
          type="text"
        />
      </div>
      <div className="py-2">
        <Editor
          className="mt-2"
          defaultValue={description}
          editorKey="run-description"
          focused={focusedDescription}
          maxLimit={CHECKLIST_DESCRIPTION_MAX_LENGTH}
          onBlur={handleDescriptionBlur}
          onFocus={handleDescriptionFocus}
          placeholder="Checklist Description"
          setContent={handleDescriptionChange}
          size="md"
        />
      </div>

      <div className="my-10">
        <div className="flex justify-between mb-4">
          <h4 className="text-base font-bold">Checklist content</h4>
          <div className="flex gap-6 text-sm">
            <Link
              disabled={
                Object.keys(checkedItems).length === checklist.items.length
              }
              onClick={selectAllItems}
            >
              Select all ({rootItems.length})
            </Link>
            <Link
              disabled={Object.keys(checkedItems).length === 0}
              onClick={() => setCheckedItems({})}
            >
              Deselect all
            </Link>
          </div>
        </div>
        <div className="px-3 py-6 bg-white border border-gray-300 rounded-md sm:p-8">
          <checklistTemplateContext.Provider
            value={{
              checklist,
              isOwner,
              rootItems,
              checkedItems,
              handleCheck: handleItemCheck,
            }}
          >
            <ChecklistPreview />
          </checklistTemplateContext.Provider>
        </div>
      </div>

      <div className="p-6 mb-10 bg-white border border-gray-300 rounded-md">
        <div className="flex flex-col gap-5 sm:flex-row sm:gap-8">
          <div className="w-full">
            <div>
              <h4 className="mb-4 text-base font-bold">Run at</h4>
              <DatePicker
                className="placeholder-gray-700 input input--md"
                customInput={<CustomDateInput aria-label="start date" />}
                dateFormat="MM/dd/yyyy h:mm aa"
                dropdownMode="select"
                minDate={new Date()}
                onChange={(date) => {
                  const dateToSet = startedAt
                    ? date
                    : new Date(date.toString()).setHours(9, 0, 0, 0)

                  handleDateValidation(dateToSet as Date)
                  setStartedAt(new Date(dateToSet as number))
                }}
                placeholderText="Start Date"
                selected={startedAt}
                shouldCloseOnSelect={false}
                showMonthDropdown
                showTimeInput
                showYearDropdown
                timeInputLabel="Time:"
              />
            </div>
            {pattern === "custom" && (
              <div className="w-full mt-8">
                <select
                  className="my-4 input input--md"
                  disabled={!startedAt}
                  id="repeat"
                  name="frequency"
                  onChange={handleCustomRepeat}
                  value={customRepeat}
                >
                  {Object.keys(customOptions).map((option, index) => (
                    <option key={index} value={option}>
                      {customOptions[option]}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
          <div className="w-full">
            <h4 className="mb-4 text-base font-bold">Repeat</h4>
            <div className="flex">
              <span className="flex items-center mr-2">
                <Icon name="Repeat" />
              </span>
              <select
                className="input input--md"
                disabled={!startedAt}
                id="repeat"
                name="frequency"
                onChange={handleChangeFrequency}
                value={pattern}
              >
                {Object.keys(repeatOptions).map((option, index) => (
                  <option key={index} value={repeatOptions[option].value}>
                    {repeatOptions[option].title}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="mt-4">
          {customRepeat === "daily" && pattern === "custom" && (
            <div className="my-4">
              <Counter
                handleCounter={handleCounter}
                pattern="day(s)"
                value={counter}
              />
            </div>
          )}
          {customRepeat === "weekly" && pattern === "custom" && (
            <>
              <div className="my-6">
                <Counter
                  handleCounter={handleCounter}
                  pattern="week(s)"
                  value={counter}
                />
              </div>
              <div className="my-6">
                <p className="mb-3">On:</p>
                <div className="grid grid-cols-4 gap-6 pb-4 mx-auto sm:grid-cols-7 w-max-content">
                  {Object.keys(weekDays).map((day) => (
                    <div
                      className="flex flex-col justify-center text-center"
                      key={day}
                    >
                      <input
                        className="mb-2"
                        id={day}
                        name={day}
                        onClick={() => handleWeekdaysCheck(day)}
                        type="checkbox"
                      />{" "}
                      <label htmlFor={day}>{weekDays[day]}</label>
                    </div>
                  ))}
                </div>
              </div>
              {/* <p>Every {counter} week on</p> */}
            </>
          )}
          {customRepeat === "monthly" && pattern === "custom" && (
            <>
              <div className="my-6">
                <Counter
                  handleCounter={handleCounter}
                  pattern="month(s)"
                  value={counter}
                />
              </div>
              <div className="my-6">
                <p className="mb-3">On:</p>
                <div className="grid grid-cols-5 p-4 mx-auto border border-solid rounded w-52 border-blueGray-200">
                  {[...Array(28)].map((_, index) => (
                    <span
                      className={`text-center text-sm cursor-pointer p-1 hover:bg-gray-500 ${
                        monthlyDates[index + 1] && "bg-blue-500 text-white"
                      }`}
                      key={index}
                      onClick={() => handleDatePicker(index + 1)}
                    >
                      {index + 1}
                    </span>
                  ))}
                </div>
              </div>
            </>
          )}
          {customRepeat === "yearly" && pattern === "custom" && (
            <>
              <div className="my-6">
                <Counter
                  handleCounter={handleCounter}
                  pattern="year(s)"
                  value={counter}
                />
              </div>
              <div className="my-6">
                <p className="mb-3">On:</p>
                <div className="grid grid-cols-4 p-4 border border-solid rounded border-blueGray-200">
                  {months.map((month, index) => (
                    <span
                      className={`text-center cursor-pointer p-2 hover:bg-gray-300 ${
                        yearlyRunOnMonths[month] && "bg-blue-500 text-white"
                      }`}
                      key={month}
                      onClick={() => handleMonthPicker(month, index + 1)}
                    >
                      {month}
                    </span>
                  ))}
                </div>
              </div>
            </>
          )}
          {pattern !== "single-occurrence" && (
            <div className="flex flex-col gap-3 my-6 sm:flex-row sm:gap-8">
              <div className="flex items-center">
                <label>
                  <input
                    checked={repeatUntil === "forever"}
                    className="mr-4"
                    disabled={!startedAt}
                    name="end-at"
                    onChange={handleChangeRepeatUntil}
                    type="radio"
                    value="forever"
                  />{" "}
                  Forever
                </label>
              </div>
              <div className="flex items-center">
                <label className="flex items-center mr-4">
                  <input
                    checked={repeatUntil === "on-date"}
                    className="mr-4"
                    disabled={!startedAt}
                    name="end-at"
                    onChange={handleChangeRepeatUntil}
                    type="radio"
                    value="on-date"
                  />{" "}
                  Until{" "}
                </label>
                <DatePicker
                  className="input input--sm"
                  customInput={<CustomDateInput aria-label="start date" />}
                  dateFormat="MM/dd/yyyy"
                  disabled={repeatUntil !== "on-date"}
                  dropdownMode="select"
                  minDate={dayjs(startedAt).add(1, "day").toDate()} // Prevent same date selection as start date
                  onChange={(date) => {
                    handleDateValidation(date as Date)
                    handleEndDate(date as Date)
                  }}
                  placeholderText="End Date"
                  selected={until}
                  showMonthDropdown
                  showYearDropdown
                  timeInputLabel="Time:"
                />
              </div>
            </div>
          )}
        </div>
        {pattern === "custom" && (
          <div className="mt-8 font-bold underline capitalize-first">
            {scheduleText}.
          </div>
        )}
      </div>

      <div>
        <Button
          aria-label="create"
          disabled={loading || !isDateValid}
          onClick={handleSubmit}
          type="submit"
        >
          {loading ? "Creating..." : "Create scheduled checklist"}
        </Button>
      </div>
    </div>
  )
}

function CustomDateInput(props: GenericObject): JSX.Element {
  return <input type="text" {...props} readOnly />
}

function Counter({
  handleCounter,
  value,
  pattern,
}: {
  handleCounter: (e: React.FocusEvent<HTMLInputElement>) => void
  value: number
  pattern?: string
}): JSX.Element {
  return (
    <div className="flex items-center justify-between gap-8 my-4 w-max-content">
      <label htmlFor="">Every</label>
      <input
        className="w-20 input input--sm"
        defaultValue={1}
        max={999}
        min={1}
        onChange={handleCounter}
        type="number"
        value={value}
      />
      <span>{pattern || ""}</span>
    </div>
  )
}
