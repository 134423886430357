import React from "react"
import * as yup from "yup"
import {
  Button,
  Form,
  SetUsernameField,
  USERNAME_VALIDATION_SCHEMA,
} from "~src/components"
import { Alert } from "~src/components/Alert"
import { useState } from "~src/store"

const schema = yup.object().shape({
  username: USERNAME_VALIDATION_SCHEMA,
})

export interface SetUsernameFormProps {
  onSubmit: (username: string) => void
  updating?: boolean
}

export function SetUsernameForm({
  onSubmit,
  updating,
}: SetUsernameFormProps): JSX.Element {
  const [isUsernameAvailable, setIsUsernameAvailable] = React.useState(false)
  const {
    auth: { error },
  } = useState()

  function handleSubmit(values: { username: string }): void {
    if (isUsernameAvailable) {
      onSubmit(values.username)
    }
  }

  return (
    <Form onSubmit={handleSubmit} schema={schema} values={{}}>
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        setErrors,
      }: GenericObject): GenericObject => (
        <form onSubmit={handleSubmit}>
          {error && <Alert className="mb-2">{error.toString()}</Alert>}
          <SetUsernameField
            error={errors?.username}
            isUsernameAvailable={isUsernameAvailable}
            onChange={handleChange}
            setErrors={setErrors}
            setIsUsernameAvailable={setIsUsernameAvailable}
            value={values.username}
          />
          <div className="my-8">
            <Button
              disabled={updating || !isUsernameAvailable}
              fullWidth
              leftIcon="Lock"
              onClick={handleSubmit}
              type="submit"
            >
              Save
            </Button>
          </div>
        </form>
      )}
    </Form>
  )
}
